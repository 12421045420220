import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
const HomePage1 = () => {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path='/homepage' element={WelcomePage} />
    //     <Route path='/detailsPage' element={DetailsPage} />
    
    //   </Routes>
    // </BrowserRouter>
    <WelcomePage/>
    // <DetailsPage/>

  );
};

const WelcomePage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">WELCOME TO SYNERGY</h1>
      <p className="text-lg text-center mb-8">
        Synergy Industrial Pvt. Ltd. Co.: Your trusted partner in electromechanical, automation, and instrumentation solutions.
        Serving Ethiopian and East African industries with expertise and excellence. Committed to delivering world-class engineering services.
      </p>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Card
          image="/card1.jpg"
          title="Card 1"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
        />
        <Card
          image="/card2.jpg"
          title="Card 2"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
        />
        <Card
          image="/card3.jpg"
          title="Card 3"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
        />
      </div>
    </div>
  );
};

const DetailsPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">Details Page</h1>
      <p className="text-lg text-center mb-8">
        This is the details page. Add your content here.
      </p>
    </div>
  );
};

const Card = ({ image, title, description }) => {
  return (
    <Link to="/details" className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
      <img className="w-full h-48 object-cover" src={image} alt={title} />
      <div className="p-4">
        <h3 className="text-xl font-medium mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
        <Link to="/details" className="text-blue-500 inline-block mt-2">Show more</Link>
      </div>
    </Link>
  );
};

export default HomePage1;










// import React, { useState } from 'react';

// const ProductList1 = () => {
//   const [activeAccordion, setActiveAccordion] = useState(null);

//   const products = [
//     {
//       id: 1,
//       name: 'Product 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 2,
//       name: 'Product 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 3,
//       name: 'Product 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//   ];

//   const handleAccordion = (productId) => {
//     setActiveAccordion(productId === activeAccordion ? null : productId);
//   };

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Product List</h1>
//       {products.map((product) => (
//         <div key={product.id} className="border rounded-lg p-4 mb-4">
//           <div
//             className="flex items-center justify-between cursor-pointer"
//             onClick={() => handleAccordion(product.id)}
//           >
//             <h3 className="text-xl font-semibold">{product.name}</h3>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className={`h-6 w-6 transform ${activeAccordion === product.id ? 'rotate-180' : ''}`}
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M19 9l-7 7-7-7"
//               />
//             </svg>
//           </div>
//           {activeAccordion === product.id && (
//             <div className="mt-2">
//               <p>{product.description}</p>
//             </div>
//           )}
//         </div>
//       ))}

//       <div className="border rounded-lg p-4 mt-8">
//         <h2 className="text-2xl font-bold mb-4">Our Brand and Insights</h2>
//         <p>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.
//         </p>
//       </div>

//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8">
//         <SupplierCard
//           image="/supplier1.jpg"
//           name="Supplier 1"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
//         />
//         <SupplierCard
//           image="/supplier2.jpg"
//           name="Supplier 2"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
//         />
//         <SupplierCard
//           image="/supplier3.jpg"
//           name="Supplier 3"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend."
//         />
//       </div>
//     </div>
//   );
// };

// const SupplierCard = ({ image, name, description }) => {
//   return (
//     <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
//       <img className="w-full h-48 object-cover" src={image} alt={name} />
//       <div className="p-4">
//         <h3 className="text-xl font-medium mb-2">{name}</h3>
//         <p className="text-gray-600">{description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductList1;





// import React from 'react';
// import { motion } from 'framer-motion';

// const Services = () => {
//   const services = [
//     {
//       id: 1,
//       title: 'Service 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service1.jpg',
//     },
//     {
//       id: 2,
//       title: 'Service 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service2.jpg',
//     },
//     {
//       id: 3,
//       title: 'Service 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service3.jpg',
//     },
//   ];

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Services</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
//         {services.map((service) => (
//           <motion.div
//             key={service.id}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.2 }}
//             className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg"
//           >
//             <img className="w-full h-48 object-cover" src={service.image} alt={service.title} />
//             <div className="p-4">
//               <h3 className="text-xl font-medium mb-2">{service.title}</h3>
//               <p className="text-gray-600">{service.description}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Services;




// import React, { useState } from 'react';

// const Testimonials = () => {
//   const testimonials = [
//     {
//       id: 1,
//       name: 'John Doe',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 2,
//       name: 'Jane Smith',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//     {
//       id: 3,
//       name: 'David Johnson',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//     },
//   ];

//   const [activeTestimonial, setActiveTestimonial] = useState(0);

//   const handleNext = () => {
//     setActiveTestimonial((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
//   };

//   const handlePrev = () => {
//     setActiveTestimonial((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
//   };

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Testimonials</h1>
//       <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md p-6">
//         <div className="flex items-center justify-between mb-4">
//           <button onClick={handlePrev} className="text-gray-500 hover:text-gray-700">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-6 w-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M15 19l-7-7 7-7"
//               />
//             </svg>
//           </button>
//           <button onClick={handleNext} className="text-gray-500 hover:text-gray-700">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-6 w-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M9 5l7 7-7 7"
//               />
//             </svg>
//           </button>
//         </div>
//         <div className="text-center">
//           <p className="text-lg font-medium">{testimonials[activeTestimonial].content}</p>
//           <p className="text-gray-500 mt-2">- {testimonials[activeTestimonial].name}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Testimonials;





// import React from 'react';

// const TechnologicalSolutions = () => {
//   const solutions = [
//     {
//       id: 1,
//       title: 'Solution 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/solution1.jpg',
//     },
//     {
//       id: 2,
//       title: 'Solution 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/solution2.jpg',
//     },
//     {
//       id: 3,
//       title: 'Solution 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/solution3.jpg',
//     },
//   ];

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Our Technological Solutions</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
//         {solutions.map((solution) => (
//           <div key={solution.id} className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg">
//             <img className="w-full h-48 object-cover" src={solution.image} alt={solution.title} />
//             <div className="p-4">
//               <h3 className="text-xl font-medium mb-2">{solution.title}</h3>
//               <p className="text-gray-600">{solution.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TechnologicalSolutions;








// import React from 'react';

// const Journals = () => {
//   const journals = [
//     {
//       id: 1,
//       title: 'Journal 1',
//       date: 'January 1, 2022',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/journal1.jpg',
//     },
//     {
//       id: 2,
//       title: 'Journal 2',
//       date: 'February 15, 2022',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/journal2.jpg',
//     },
//     {
//       id: 3,
//       title: 'Journal 3',
//       date: 'March 10, 2022',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/journal3.jpg',
//     },
//   ];

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Journals</h1>
//       {journals.map((journal) => (
//         <div key={journal.id} className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden my-8">
//           <img className="w-full h-48 object-cover" src={journal.image} alt={journal.title} />
//           <div className="p-6">
//             <h2 className="text-2xl font-bold mb-2">{journal.title}</h2>
//             <p className="text-gray-600 mb-4">{journal.date}</p>
//             <p className="text-gray-700">{journal.content}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Journals;








// import React, { useState, useEffect } from 'react';

// const Home = () => {
//   const [activeCard, setActiveCard] = useState(0);
//   const cards = [
//     {
//       id: 1,
//       title: 'Card 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//       image: '/card1.jpg',
//     },
//     {
//       id: 2,
//       title: 'Card 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//       image: '/card2.jpg',
//     },
//     {
//       id: 3,
//       title: 'Card 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//       image: '/card3.jpg',
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
//     }, 2000);

//     return () => clearInterval(interval);
//   }, [cards.length]);

//   return (
//     <div className="container mx-auto">
//       <div className="max-w-3xl mx-auto">
//         <img className="w-full h-96 object-cover" src={cards[activeCard].image} alt={cards[activeCard].title} />
//         <div className="bg-white shadow-lg rounded-lg overflow-hidden my-8">
//           <div className="p-6">
//             <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
//             <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
//               View Details
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;