  import React, { useState } from 'react';
  import Droga from "../assets/Droga.jpg";


  const FormDownload = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [youid, setYouid] = useState('');
    const [description, setDescription] = useState('');

    

    const handleFormDownload = () => {
      // Logic to generate and download the form
      const logoUrl = Droga; // Replace with the actual path to your logo image
      const formContent = `Name: ${name}\nEmail: ${email}\nDepartment: ${department}` + `\nYou ID: ${youid}` + `\nDescription: ${description}`;
      const template = `
      <div style="display: flex; justify-content: center;">
        <div style="width: 300px; height: 66vh; padding: 20px; background-color: white; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); border-radius: 8px; overflow: auto;">
        <div style="text-align: center;">
    <img src="${logoUrl}" alt="Logo" style="max-width: 200px; margin-bottom: 20px;">
  </div>
          <h2 style="text-align: center; font-size: 24px;">Form Data:</h2>
          <div style="text-align: left; max-height: 66vh;">
            <pre style="font-size: 18px; white-space: pre-wrap; word-wrap: break-word;">${formContent}</pre>
          </div>
        </div>
      </div>
    `;


      const element = document.createElement('a');
      const file = new Blob([template], { type: 'text/html' });
      element.href = URL.createObjectURL(file);
      element.download = 'form.html';
      document.body.appendChild(element);
      element.click();
    };

    const handleFormSend = () => {
      // Logic to send the form data to the IT manager
      console.log('Sending form:', { name, email, department ,youid, description });
      // Additional logic can be added here, such as making an API call to send the form data
    };

    return (
      <div className="p-4 bg-dro_yellow w-full md:w-1/3 mx-auto my-2 overflow-y-auto h-2/5">
        <h2 className="text-2xl font-bold mb-4 text-center">Fill in the Form</h2>
        <div className="mb-4">
          <label htmlFor="name" className="block mb-2">Name:</label>
          <input
            id="name"
            type="text"
            className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email:</label>
          <input
            id="email"
            type="email"
            className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="department" className="block mb-2">Department:</label>
          <input
            id="department"
            type="text"
            className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block mb-2">Description:</label>
          <input
            id="description"
            type="text"
            className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="youid" className="block mb-2">You ID:</label>
          <input
            id="youid"
            type="text"
            className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
            value={youid}
            onChange={(e) => setYouid(e.target.value)}
          />
        </div>
        <div className="flex justify-center">
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4" onClick={handleFormDownload}>
            Download Form
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={handleFormSend}>
            Send Form
          </button>
        </div>
      </div>
    );
  };

  export default FormDownload;











