// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Testimonial = ({ id, name, title, image, quote ,fetchTestimonials }) => {
//   const handleDelete = (postId) => {
//     console.log(`Delete button clicked for testimonial ${postId}`);
//     const token = localStorage.getItem('token');
//     const headers = {
//       Authorization: `token ${token}`,
//     };

//     axios
//       .delete(`https://api.synergyindustri.com/api/testimonial/${postId}/delete/`, { headers })
//       .then((response) => {
//         console.log('Testimonial post deleted successfully:', response.data);
//         // Handle successful delete
//         fetchTestimonials()
//       })
//       .catch((error) => {
//         console.error('Error deleting testimonial post:', error);
//       });
//   };




//   const handleEdit = (postId) => {
//     console.log(`Change picture clicked for blog post ${postId}`);
//     const handleImageChange = (event) => {
//       const imageFile = event.target.files[0];
//       if (imageFile) {
//         const reader = new FileReader();
//         reader.onload = (e) => {
//           const base64Image = e.target.result;
  
//           const title = prompt('Enter the new title:');
//           const quote = prompt('Enter the new quote:');
//           const name = prompt('Enter the new name:');
//           if (title && quote && name) {
//             const token = localStorage.getItem('token');
//             const headers = {
//               Authorization: `token ${token}`,
//             };
  
//             axios
//               .put(`https://api.synergyindustri.com/api/testimonial/${postId}/`, { image: base64Image, title, quote ,name }, { headers })
//               .then((response) => {
//                 console.log('Image changed successfully:', response.data);
//                 fetchTestimonials(); // Refresh the blog post list
//               })
//               .catch((error) => {
//                 console.error('Error changing image:', error);
//               });
//           }
//         };
//         reader.readAsDataURL(imageFile);
//       }
//     };
  
//     const inputElement = document.createElement('input');
//     inputElement.type = 'file';
//     inputElement.accept = 'image/*';
//     inputElement.addEventListener('change', handleImageChange);
//     inputElement.click();
//   };

//   const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));


//   return (
//     <div className="flex items-center justify-center bg-gray-100 py-8 px-4 sm:px-6 lg:px-8 ">
//       <div className="max-w-3xl w-3/4 mx-auto">
//         <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:bg-gray-200 hover:cursor-pointer">
//           <div className="flex justify-center">
//             <img
//               className="h-36 w-36 rounded-full mt-2"
//               src={image}
//               alt={name}
//             />
//           </div>
//           <div className="px-6 py-4">
//             <h3 className="text-xl font-semibold text-gray-800">{name}</h3>
//             <p className="text-gray-600 text-sm">{title}</p>
//             <blockquote className="mt-4 text-lg text-gray-800">
//               "{quote}"
//             </blockquote>
//           </div>


//           {isLoggedIn && (
//           <div className="flex justify-between items-center px-6 py-2">
//             <button
//               className="text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1"
//               onClick={() => handleEdit(id)}
//             >
//               Edit
//             </button>
//             <button
//               className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
//               onClick={() => handleDelete(id)}
//             >
//               Delete
//             </button>
//           </div>
//           )}
//         </div>
        
//       </div>
      
//     </div>
    
    
    
//   );
// };

// const TestimonialSection = () => {
//   const [testimonials, setTestimonials] = useState([]);


//   const fetchTestimonials = () => {
//     axios.get('https://api.synergyindustri.com/api/testimonials-getall/')
//       .then(response => {
//         setTestimonials(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching testimonials:', error);
//       });
//   };

//   useEffect(() => {
//     fetchTestimonials();
//   }, []);

//   const handleAddImage = () => {
//     const handleImageChange = (event) => {
//       const imageFile = event.target.files[0];
//       if (imageFile) {
//         const title = prompt('Enter the title:');
//         const quote = prompt('Enter the quote:');
//         const name = prompt('Enter the name:');
//         if (title && quote && name) {
//           const reader = new FileReader();
//           reader.onload = (e) => {
//             const base64Image = e.target.result;
  
//             const formData = new FormData();
//             formData.append('image', base64Image);
//             formData.append('title', title);
//             formData.append('quote', quote);
//             formData.append('name', name);
  
//             const token = localStorage.getItem('token');
//             const headers = {
//               Authorization: `token ${token}`,
//               'Content-Type': 'multipart/form-data',
//             };
  
//             axios
//               .post('https://api.synergyindustri.com/api/testimonials/create/', formData, { headers })
//               .then((response) => {
//                 console.log('Image added successfully:', response.data);
//                 fetchTestimonials(); // Refresh the blog post list
//               })
//               .catch((error) => {
//                 console.error('Error adding image:', error);
//               });
//           };
//           reader.readAsDataURL(imageFile);
//         }
//       }
//     };
  
//     const inputElement = document.createElement('input');
//     inputElement.type = 'file';
//     inputElement.accept = 'image/*';
//     inputElement.addEventListener('change', handleImageChange);
//     inputElement.click();
//   };

//   const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

//   return (
//     <div>
   
//     <div>
//       {testimonials.map((testimonial, index) => (
//         <Testimonial
//           key={index}
//           id={testimonial.id}
//           name={testimonial.name}
//           title={testimonial.title}
//           image={testimonial.image}
//           quote={testimonial.quote}
//           fetchTestimonials={fetchTestimonials}
//         />
//       ))}
      


//     </div>
//     {isLoggedIn && (
//       <div className="flex justify-center items-center">
//       <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//         <div className="bg-white rounded-lg shadow-lg text-center">
//           <div className="h-48 flex items-center justify-center">
//             <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
//               Add content
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//     )}
//     </div>

    
//   );
// };

// export default TestimonialSection;






import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PacmanLoader, PropagateLoader, PuffLoader, PulseLoader, RingLoader, RiseLoader, RotateLoader, ScaleLoader, SkewLoader, SquareLoader, SyncLoader } from 'react-spinners';
import logo from "../assets/cara.png";
import "./testimoniallogo.css" 
import LoadingLogo from './LoadingLogo';
const Testimonial = ({ id, name, title, image, quote, fetchTestimonials }) => {
  const handleDelete = (postId) => {
    console.log(`Delete button clicked for testimonial ${postId}`);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `token ${token}`,
    };


    // useEffect(() => {
    //   if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
    //     window.location.href = '/';
    //   }
    // }, []);
  

    axios
      .delete(`https://api.synergyindustri.com/api/testimonial/${postId}/delete/`, { headers })
      .then((response) => {
        console.log('Testimonial post deleted successfully:', response.data);
        // Handle successful delete
        fetchTestimonials();
      })
      .catch((error) => {
        console.error('Error deleting testimonial post:', error);
      });
  };

  const handleEdit = (postId) => {
    console.log(`Change picture clicked for blog post ${postId}`);
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;

          const title = prompt('Enter the new title:');
          const quote = prompt('Enter the new quote:');
          const name = prompt('Enter the new name:');
          if (title && quote && name) {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
            };

            axios
              .put(`https://api.synergyindustri.com/api/testimonial/${postId}/`, { image: base64Image, title, quote, name }, { headers })
              .then((response) => {
                console.log('Image changed successfully:', response.data);
                fetchTestimonials(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error changing image:', error);
              });
          }
        };
        reader.readAsDataURL(imageFile);
      }
    };

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };

  const isLoggedIn = !!(
    localStorage.getItem('token') && localStorage.getItem('user')
  );

  return (
    <div className="flex items-center justify-center bg-gray-100 py-8 px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-3xl w-full mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:bg-gray-200 hover:cursor-pointer">
          <div className="flex justify-center">
            <img className="h-36 w-36 rounded-full mt-2" src={image} alt={name} />
          </div>
          <div className="px-6 py-4">
            <h3 className="text-xl font-semibold text-gray-800">{name}</h3>
            <p className="text-gray-600 text-sm">{title}</p>
            <blockquote className="mt-4 text-lg text-gray-800 text-justify">"{quote}"</blockquote>
          </div>

          {isLoggedIn && (
            <div className="flex justify-between items-center px-6 py-2">
              <button
                className="text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1"
                onClick={() => handleEdit(id)}
              >
                Edit
              </button>
              <button
                className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
                onClick={() => handleDelete(id)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TestimonialSection = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTestimonials = () => {
    axios
      .get('https://api.synergyindustri.com/api/testimonials-getall/')
      .then((response) => {
        setTestimonials(response.data);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false); // Set loading state to false even if there's an error
      });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);



useEffect(() => {
    if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
      window.location.href = '/';
    }
  }, []);
  
  const handleAddImage = () => {
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const title = prompt('Enter the title:');
        const quote = prompt('Enter the description:');
        const name = prompt('Enter the name:');
        if (title && quote && name) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
  
            const formData = new FormData();
            formData.append('image', base64Image);
            formData.append('title', title);
            formData.append('name', name);
            formData.append('quote', quote);
      
  
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
              'Content-Type': 'multipart/form-data',
            };
  
            axios
              .post('https://api.synergyindustri.com/api/testimonials/create/', formData, { headers })
              .then((response) => {
                console.log('Image added successfully:', response.data);
                fetchTestimonials(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error adding image:', error);
              });
          };
          reader.readAsDataURL(imageFile);
        }
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };

  const isLoggedIn = !!(
    localStorage.getItem('token') && localStorage.getItem('user')
  );

  return (
    <div>
    <h1 className="text-4xl font-bold my-8 flex flex-row intem-center justify-center">Testimonials</h1>
      <div>
        {isLoading ? (
// dd

<LoadingLogo />
        ) : (
          testimonials.map((testimonial, index) => (
            <Testimonial
              key={index}
              id={testimonial.id}
              name={testimonial.name}
              title={testimonial.title}
              image={testimonial.image}
              quote={testimonial.quote}
              fetchTestimonials={fetchTestimonials}
            />
          ))
        )}
      </div>

      {isLoggedIn && (
        <div className="flex justify-center items-center">
          <button
            className="text-white hover:bg-green-800 bg-green-600 rounded-lg px-4 py-2 mt-4"
            onClick={handleAddImage}
          >
            Add Testimonial
          </button>
        </div>
      )}
    </div>
  );
};

export default TestimonialSection;