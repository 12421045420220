import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function PopupCard({ children, onClose }) {
  return (
    <div className="fixed left-0 top-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-lg">
        {children}
        <button
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </div>
  );
}

function Changepass() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isChangePasswordPopupOpen, setChangePasswordPopupOpen] = useState(false);
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const handlePasswordChange = () => {
    setChangePasswordPopupOpen(true);
  };

  const handleLogout = () => {
    setLogoutPopupOpen(true);
  };
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
  return (
    <div>
      <Link to="/">
        {user && token ? (
          <>
            <div
              className="text-white bg-blue-600 p-2 text-lg rounded-lg hover:bg-blue-300 hover:cursor-pointer absolute mt-[-20px]"
              onClick={toggleDropdown}
              onBlur={() => setDropdownVisible(false)}
              tabIndex="0"
            >
              Hi, {user.username}
              {isDropdownVisible && (
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '5px',
                    marginTop: '20px',
                  }}
                >
                  <p
                    className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-400 text-xs"
                    onClick={handlePasswordChange}
                  >
                    Change Password
                  </p>
                  <p
                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-400 mt-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <Link to="/login">
            <button
              style={{ position: 'absolute', top: '10px', right: '[-5px]' }}
              className="bg-synergy-blue text-white px-4 py-1 rounded-lg"
            >
              Login
            </button>
          </Link>
        )}
      </Link>

      {isChangePasswordPopupOpen && (
        <PopupCard onClose={() => setChangePasswordPopupOpen(false)}>
          <h2 className="text-2xl font-bold mb-4 text-center">Change Password</h2>
          {/* Change password form */}
        </PopupCard>
      )}

      {isLogoutPopupOpen && (
        <PopupCard onClose={() => setLogoutPopupOpen(false)}>
          <h2 className="text-2xl font-bold mb-4 text-center">Logout</h2>
          <p>Are you sure you want to logout?</p>
          <div className="flex justify-center mt-4">
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-400 mr-2"
              onClick={handleLogout}
            >
              Logout
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400"
              onClick={() => setLogoutPopupOpen(false)}
            >
              Cancel
            </button>
          </div>
        </PopupCard>
      )}
    </div>
  );
}

export default Changepass;