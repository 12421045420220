import './App.css';
import { useState } from 'react';
import NavBar from './components/NavBar';
import Body from './components/Body';
import SimpleSlider from './components/HeroCarousel';
import HeroSection from './components/HeroSection';
import OurBestSellers from './components/OurBestSellers';
import Ingridients from './components/Ingridients';
import JournalSection from './components/JournalSection';
import BsText from './components/BsText';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import SinglePage from "./components/SinglePage";
import JournalPage from "./components/JournalPage";
import Cart from './components/Cart';
import FollowONIG from './components/FollowONIG';
import Products from './components/Products';
import CartHold from './components/CartHold';
import SPFooter from './components/SPFooter';
import Under20 from './components/Under20';
import Under10 from './components/Under10';
import ForHer from './components/ForHer';
import ForHim from './components/ForHim';
import MobileNav from './components/MobileNav';
import ContactUs from './components/Contatusnew';
import AboutUs from './components/Aboutus';
import BotChat from './components/BotChat';
import ProductList from './components/ProductList';
import Loginpage from './components/Loginpage';
import ChangePasswordComponent from './components/ChangePasswordComponent';
import TestimonialSection from './components/TestimonialSection';
import Changepass from './components/Changepass';
import Newtest from './components/Newtest';
import PopupComponent from './components/PopupComponent';
import NavAvatar from './components/NavAvatar';
import HomePage1 from './components/HomePage1';
import WelcomePage from './components/WelcomePage';
import ProductListt from './components/ProductListt';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import TechnologicalSolutions from './components/TechnologicalSolutions';
import Home from './components/Home';
import Journals from './components/Journals';
import Navbarr from './components/Navbarr';
import ToastComponent from './components/ToastComponent';
import RegisterForm from './components/RegisterForm';
import LoadingLogo from './components/LoadingLogo';
import Someofourcustomers from'./components/Someofourcustomers';
import CardComponent from './components/CardComponent';
import FormComponent from './components/FormComponent';
import SignInSide from './components/SignInSide';
import SignUp from './components/SignUp';
import Newsn from './components/Newsn';
import Checkout from './components/Checkout';
import FormDownload from './components/FormDownload';
import Navbard from './components/Navbard';
import Drogalogin from './components/Drogalogin';
import AboutUsPage from './components/AboutUsPage';
import Newproducttoaboutus from './components/Newproducttoaboutus';
import EmployeeSendMessage from  './components/EmployeeSendMessage';
import ManagerMessageList from './components/ManagerMessageList';
import Notification from './components/Notification';
import Botchatdroga from './components/Botchatdroga';
import Chatbot from './components/Chatbot';
import OurMission from './components/OurMission';
import Atest from './components/Atest'
// import React, { useState } from 'react';
// import EmployeeSendMessage from './EmployeeSendMessage';
// import ManagerMessageList from './ManagerMessageList';

// function App() {

//   return (
//     <div className="App">
//       <BrowserRouter>

//         <Routes >
        
//           <Route path='/' exact element={<>   <NavBar /> <MobileNav />  <SimpleSlider />  <HeroSection />  <BsText /> <ProductList />   {/*<OurBestSellers />*/}  <Ingridients />  <JournalSection /> <FollowONIG /> <SPFooter />  </>} />
//           <Route path='/:id' exact element={<> <NavBar /> <MobileNav />  <SinglePage /> </>} />
//           <Route path='/journal/april' element={<> <NavBar /> <MobileNav />  <JournalPage /> </>} />
//           <Route path='/cart' exact element={<>  <NavBar /> <CartHold /></>} />
//           <Route path='/under20' element={<> <NavBar />  <Under20 /> </>} />
//           <Route path='/under40' element={<> <NavBar />  <Under10 /> </>} />
//           <Route path='/forher' element={<> <NavBar />  <ForHer /> </>} />
//           <Route path='/forhim' element={<> <NavBar />  <ForHim /> </>} />
//           <Route path='/contactus' element={<> <NavBar />  <ContactUs /> </>} />
//           <Route path='/Aboutus' element={<> <NavBar />  <AboutUs /> </>} />
//           <Route path='/botchat' element={<> <NavBar />  <BotChat /> </>} />
//           <Route path='/productlist' element={<> <NavBar />  <ProductList /> </>} />
//           <Route path='/login' element={<> <Loginpage /> </>} />
//           <Route path='/changepassword' element={<> <ChangePasswordComponent /> </>} />
//           <Route path='/TestimonialSection' element={<> <NavBar />  <TestimonialSection /> </>} />
//           <Route path='/test' element={<> <NavBar />  <test /> </>} />
//           <Route path='/Changepass' element={<> <Changepass />  <test /> </>} />
//           <Route path='/Newtest' element={<> <Newtest /> </>} />
//           <Route path='/PopupComponent' element={<> <PopupComponent /> </>} />
//           <Route path='/NavAvatar' element={<> <NavAvatar /> </>} />
//           <Route path='/HomePage1' element={<> <HomePage1 /> </>} />
//           <Route path='/WelcomePage' element={<> <WelcomePage /> </>} />
//           <Route path='/ProductListt' element={<> <ProductListt /> </>} />
//           <Route path='/Services' element={<> <Services /> </>} />
//           <Route path='/Testimonials' element={<> <Testimonials /> </>} />
//           <Route path='/TechnologicalSolutions' element={<> <TechnologicalSolutions /> </>} />
//           <Route path='/Home' element={<> <Home /> </>} />
//           <Route path='/Journals' element={<> <Journals /> </>} /> <JournalSection />
//         </Routes>

//       </BrowserRouter>




//     </div>
//   );
// }

// export default App;







function App() {

  return (
    <div className="App">
      <BrowserRouter>

        <Routes >
          <Route path='/' exact element={<>   <Navbarr /> <Home />  <WelcomePage /> <Ingridients />  <ProductList /> <Services /> <TestimonialSection /> <ContactUs /> <Someofourcustomers /> <SPFooter /> <BotChat /> </>} />
          // <Route path='/:id' exact element={<> <Navbarr /> <SinglePage /> </>} />
          <Route path='/journal/april' element={<> <Navbarr /> <JournalPage /> </>} />
          // <Route path='/cart' exact element={<>  <NavBar /> <CartHold /></>} />
          // <Route path='/under20' element={<> <NavBar />  <Under20 /> </>} />
          // <Route path='/under40' element={<> <NavBar />  <Under10 /> </>} />
          // <Route path='/forher' element={<> <NavBar />  <ForHer /> </>} />
          // <Route path='/forhim' element={<> <NavBar />  <ForHim /> </>} />
          <Route path='/contactus' element={<> <Navbarr />  <ContactUs /> </>} />
          <Route path='/Aboutus' element={<> <Navbarr />  <AboutUs /> </>} />
          <Route path='/botchat' element={<> <Navbarr />  <BotChat /> </>} />
          <Route path='/productlist' element={<> <Navbarr />  <ProductList /> </>} />
          <Route path='/login' element={<> <Loginpage /> </>} />
          <Route path='/changepassword' element={<> <ChangePasswordComponent /> </>} />
          <Route path='/TestimonialSection' element={<> <Navbarr />  <TestimonialSection /> </>} />
          // <Route path='/test' element={<> <NavBar />  <test /> </>} />
          <Route path='/Changepass' element={<> <Changepass />  <test /> </>} />
          // <Route path='/Newtest' element={<> <Newtest /> </>} />
          // <Route path='/PopupComponent' element={<> <PopupComponent /> </>} />
          <Route path='/NavAvatar' element={<> <NavAvatar /> </>} />
          // <Route path='/HomePage1' element={<> <HomePage1 /> </>} />
          <Route path='/WelcomePage' element={<> <WelcomePage /> </>} />
          <Route path='/Services' element={<> <Navbarr /> <Services />   </>} />
          <Route path='/Testimonials' element={<> <Testimonials /> </>} />
          <Route path='/TechnologicalSolutions' element={<> <TechnologicalSolutions /> </>} />
          <Route path='/Home' element={<> <Home /> </>} />
          // <Route path='/Journals' element={<> <Journals /> </>} />
          <Route path='/ToastComponent' element={<> <ToastComponent /> </>} />
          <Route path='/RegisterForm' element={<> <RegisterForm /> </>} />
          <Route path='/LoadingLogo' element={<> <LoadingLogo /> </>} />
          <Route path='/CardComponent' element={<> <CardComponent /> </>} />
          <Route path='/FormComponent' element={<> <FormComponent /> </>} />
          // <Route path='/SignInSide' element={<> <SignInSide /> </>} />
          // <Route path='/SignUp' element={<> <SignUp /> </>} />
          // <Route path='/Newsn' element={<> <Botchatdroga /> <Notification /><Navbard /> <Newsn /> </>} />
          <Route path='/Checkout' element={<> <Checkout /> </>} />
          <Route path='/FormDownload' element={<> <Navbard /> <FormDownload /> </>} />
          <Route path='/Navbard' element={<> <Notification /><Navbard /> </>} />
          // <Route path='/Drogalogin' element={<> <Drogalogin /> </>} />
          <Route path='/AboutUsPage' element={<> <AboutUsPage /> </>} />
          <Route path='/Newproducttoaboutus' element={<> <Newproducttoaboutus /> </>} />
          // <Route path='/EmployeeSendMessage' element={<> <EmployeeSendMessage /> </>} />
          // <Route path='/ManagerMessageList' element={<> <ManagerMessageList /> </>} />
          // <Route path='/Notification' element={<> <Notification /> </>} />
          // <Route path='/Botchatdroga' element={<> <Botchatdroga /> </>} />
          <Route path='/Chatbot' element={<> <Chatbot /> </>} />
          {/* <Route path='/OurMission' element={<> <OurMission /> </>} /> */}
          <Route path='/Atest' element={<> <Atest /> </>} />
        </Routes>

      </BrowserRouter>




    </div>
  );
}

export default App;











// import React, { useState } from 'react';
// import EmployeeSendMessage from  './components/EmployeeSendMessage';
// import ManagerMessageList from './components/ManagerMessageList';

// const App = () => {
//   const [messages, setMessages] = useState([]);

//   const handleSendMessage = (message) => {
//     const newMessage = {
//       id: messages.length + 1,
//       content: message,
//       sender: {
//         name: 'Employee Name',
//         role: 'Employee',
//       },
//     };
//     setMessages([...messages, newMessage]);
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-2xl font-bold mb-4">Message System</h1>
//       <div className="grid grid-cols-2 gap-4">
//         <div>
//           <EmployeeSendMessage onSendMessage={handleSendMessage} />
//         </div>
//         <div>
//           <ManagerMessageList messages={messages} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;



