










// {show && <div>
//   <ul class="dropdown-nav" onMouseLeave={dontShowHandler} >

//       <BestSellers >
      
//        </BestSellers>

//   </ul>

// </div>}

// <li class="dropdown nav-link transition-all duration-700" onMouseOver={showHandler}  >
// </li >















import logo from "../assets/cara.png";

import React, { useState } from 'react';
import { Link } from 'react-router-dom';






import "../styles/Navbar.css";
import BestSellers from './BestSellers';
import GiftSets from './GiftSets';
import Body from './Body';
import { FaShoppingBag } from "react-icons/fa";
import { useSelector } from 'react-redux';
import {BsTelephonePlusFill} from "react-icons/bs";
import NavAvatar from './NavAvatar';

const Navbarr = () => {
  // <h3 className="text-xs moving-text ">WELCOME TO SYNERGY INDUSTRIAL PVT. LTD. CO. </h3>
  const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);


    const showHandler = () => {
        setShow(true)
        setShow2(false)
        setShow3(false)
        setShow4(false)

    }




    const showHandler2 = () => {
        setShow2(true)
        setShow(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler3 = () => {
        setShow3(true)
        setShow(false)
        setShow2(false)
        setShow4(false)
    }

    const showHandler4 = () => {
        setShow4(true)
        setShow(false)
        setShow2(false)
        setShow3(false)

    }


    const dontShowHandler = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)


    }
    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location = '/';; // Redirect to homepage
      };



    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
      };



    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');


    // {user && token ? (
    //     <>
    //       <p>{user.username}</p> {/* Access the username */}
    //       <p>{user.email}</p> {/* Access the email */}
    //     </>
    //   ) : (
    //     // The user is not logged in
    //     <p>User is not logged in</p>
    //   )}

const handlepasswordchange = () => {
    window.location.href = "/changepassword";
}


  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  
  // const user = JSON.parse(localStorage.getItem('user'));
  // const token = localStorage.getItem('token');

  return (
    <nav className="bg-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
          <Link to="/">
            <img className="h-14 w-15 " src={logo} alt="Logo" />
            
            </Link>
          </div>
          <div className="hidden sm:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a href="/" className="text-gray-800 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">Home</a>
              <a href="/Aboutus" className="text-gray-800 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">About Us</a>
           
              <Link to="/productlist">
              <h4 className="text-gray-900 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium ">Products</h4>
            </Link>
    
           
              <Link to="/services">
              <div  className="text-gray-800 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">Services</div>
              </Link>
              <Link to="/TestimonialSection">
              <div className="text-gray-800 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">Testimonials</div>
              </Link>
              <Link to="/contactus">
              <div className="text-gray-800 hover:bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">Contact Us</div>
              </Link>
            </div>
          </div>



          <div className="hidden sm:block">
            <div className="ml-4 flex items-center md:ml-6">

            {user && token ? (
                           
              <div className='text-gray-100 hover:text-white px-3 py-1 rounded-md text-lg font-medium'>
              <NavAvatar/>
              </div>
            ) : (
              <Link to="/login">
              <div className="text-gray-100 hover:bg-orange-700 bg-synergy-orange hover:text-white px-3 py-1 rounded-md text-lg font-medium">Login</div>
              </Link>
            )

            }
            </div>
          </div>

          <div className="-mr-2 flex sm:hidden">
            <button type="button" onClick={toggleMobileMenu} className="text-gray-800 hover:bg-synergy-orange hover:text-white px-2 py-2 rounded-md inline-flex items-center justify-center focus:outline-none" aria-controls="mobile-menu" aria-expanded={isMobileMenuOpen ? 'true' : 'false'}>
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`sm:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">Home</div>
          </Link>
          

          <Link to="/Aboutus">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">About us</div>
          </Link>
          <Link to="/productlist">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">Products</div>
          
          </Link>
          <Link to="/services">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">Services</div>
          </Link>
          <Link to="/TestimonialSection">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">Testimonials</div>
          </Link>
          <Link to="/contactus">
          <div className="text-gray-800 hover:bg-synergy-orange hover:text-white block px-3 py-2 rounded-md text-base font-medium">Contact Us</div>
          </Link>
          {user && token ? (
                           
            <div className='text-gray-100 hover:text-white px-3 py-1 rounded-md text-lg font-medium'>
            <NavAvatar/>
            </div>
          ) : (
            <Link to="/login">
            <div className="text-gray-800 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Login</div>
            </Link>
          )

          }

          
        </div>
      </div>
    </nav>
  );
};

export default Navbarr;