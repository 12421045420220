
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingLogo from './LoadingLogo';

function Atest() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const [newValue, setNewValue] = useState({ title: '', description: '' });
    const [data, setData] = useState([]);
    // const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('user');
    const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));
    
    useEffect(() => {
      fetchBlogPosts();
    }, []);
  
    const fetchBlogPosts = () => {
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
    
      if (token) {
        headers.Authorization = `token ${token}`;
      }
    
      axios
        .get('https://api.synergyindustri.com/api/footerservice-getall/', { headers })
        .then((response) => {
          setBlogPosts(response.data);
          console.log(response.data)
          console.log("in here")
          setLoading(false);
        })  
        .catch((error) => {
          console.error('Error fetching blog posts:', error);
          setLoading(false);
        });
    };

    const handleDeletePost = (postId) => {
      console.log(`Delete button clicked for blog post ${postId}`);
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `token ${token}`,
      };
    
      axios
        .delete(`https://api.synergyindustri.com/api/footerservice/${postId}/delete/`, { headers })
        .then((response) => {
          console.log('Blog post deleted successfully:', response.data);
          fetchBlogPosts(); // Refresh the blog post list
        })
        .catch((error) => {
          console.error('Error deleting blog post:', error);
        });
    };



    const createVisison = () => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `token ${token}` };
  
      axios
        .post('https://api.synergyindustri.com/api/footerservice/create/', newValue, { headers })
        .then((response) => {
          setData([...data, response.data]);
          setNewValue({ title: '' });
        })
        .catch((error) => {
          console.error('Error creating value:', error);
        });
    };

    const handleInputChange = (event) => {
      setNewValue({ ...newValue, [event.target.name]: event.target.value });
    };

  return (
    <div className=' flex flex-col items-start justify-start'>
    <div className='font-semibold text-2xl'>
    Our Vission
    </div>
  
    <ul className="list-disc list-inside text-gray-800">
      {blogPosts.map((value) => (
        <li key={value.id} className="mb-2">
        {value.title}
          {isLoggedIn && (
          <button
            className="text-red-500 hover:text-red-700 ml-2"
            onClick={() => handleDeletePost(value.id)}
          >
            Delete
          </button>
          
          )}

        </li>
      ))}

{isLoggedIn && (
      <div className="mt-4">
      <input
        type="text"
        name="title"
        value={newValue.title}
        onChange={handleInputChange}
        placeholder="Enter a new value title"
        className="border border-gray-400 px-4 py-2 mr-2"
      />

      <button
        onClick={createVisison}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create
      </button>

    </div>
)}
    </ul>
      </div>
  ) 
}

export default Atest