import React from 'react'
import { useState } from 'react'
import "../styles/SearchBox.css"
import { AiOutlineSearch } from "react-icons/ai";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { AiFillStar } from "react-icons/ai"
import Highlighter from "react-highlight-words";


const SearchBox = () => {
  const [search, setSearch] = useState("sensors");

  const sensorsClick = "sensors";
  const ElectraSenseClick = "ElectraSense";
  const fragClick = "MechTrak"
  const PowerProbeClick = "PowerProbe";
  const hydrClick = "ElectroPulse";
  const MechSenseClick = "MechSense";
  const PulseTrackClick = "PulseTrack";
  const ElectraTrackClick = "ElectraTrack";
  const MechScanClick = "MechScan"


  const sensorsHandler = (e) => {
    setSearch(sensorsClick)
  }

  const ElectraSenseHandler = (e) => {
    setSearch(ElectraSenseClick)
  }

  const fragClickHandler = (e) => {
    setSearch(fragClick)
  }

  const PowerProbeHnadler = (e) => {
    setSearch(PowerProbeClick)
  }

  const hydraHnadler = () => {
    setSearch(hydrClick);
  }

  const MechSenseClickHandler = () => {
    setSearch(MechSenseClick);
  }

  const PulseTrackClickHandler = () => {
    setSearch(PulseTrackClick);
  }

  const ElectraTrackClickHandler = () => {
    setSearch(ElectraTrackClick);
  }

  const MechScanClickHnadler = () => {
    setSearch(MechScanClick);
  }


  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  }






  const names = [
    // 1 - 5 sensors
    {
      id: "1",
      name: "Bailey C",
      type: "sensors",
      heading: "A very delicate and nice sensors ing PowerProbe", 
      description: "This PowerProbe is very soft on the ElectraSense. It does not cause tightness or redness at all. I use it after cleansing and before moisturizer, but also just when I am hot or sweaty and need a refresh after a long walk or bike ride! So far it has not caused any breakouts or anything like that either.      ",
      stars: 5,
    },

    {
      id: "2",
      name: "Kimberly",
      type: "sensors",
      heading: "Impressed With the Sensor",
      description: "I'm Impressed by How This sensors Enhances My Driving Experience. It's Highly Responsive and Reliable. I Depend on It in my Car. I Use It Right When I Start My Engine And My Car's Systems Are Ready And Alert. I'm Glad I Installed This Sensor.      ",
      stars: 5,
    },


    {
      id: "3",
      name: "Christen",
      type: "sensors",
      heading: "Thrilled by the Advanced Sensor Technology in My Car",
      description: "I recently upgraded my car with advanced sensors, and it's made a world of difference in terms of safety and awareness on the road. Highly recommended!.",
      stars: 4,
    },

    {
      id: "4",
      name: "Vincent ",
      type: "sensors",
      heading: "Enhancing Safety and Awareness: My Experience with Car Sensors",
      description: "The sensors in my vehicle are incredibly intuitive and help me navigate through tight parking spaces effortlessly. No more worrying about accidental bumps or scratches! ",
      stars: 5,
    },

    {
      id: "5",
      name: "Morikawa",
      type: "sensors",
      heading: "Effortless Parking and Navigation: The Power of Vehicle Sensors",
      description: "One of the best features of my new car is its sophisticated sensor system. It constantly monitors my surroundings and provides helpful alerts, giving me peace of mind during my daily commute.",
      stars: 5,
    },

    // 6 - 10  ElectraSense


    {
      id: "6",
      name: "Joanne ",
      type: "ElectraSense",
      heading: "Peace of Mind on the Road: How Car Sensors Make a Difference",
      description: "ElectraSense Intelligent Electromechanical Analysis: ElectraSense offers an intelligent electromechanical analysis platform that harnesses cutting-edge technology to monitor and analyze electrical signals in mechanical components, empowering efficient troubleshooting and predictive maintenance.",
      stars: 4,
    },


    {
      id: "7",
      name: "Irene C.",
      type: "ElectraSense",
      heading: "Smarter Driving with Sensor Systems: My Impressions and Benefits",
      description: " ElectraSense Advanced Electrical Monitoring System: The ElectraSense system is an advanced electrical monitoring solution designed to capture and interpret electrical signals in real-time, providing comprehensive insights and analysis for enhanced performance and optimization.",
      stars: 4,
    },

    {
      id: "8",
      name: "Julia P",
      type: "ElectraSense",
      heading: "Love this product. So easy to use",
      description: "ElectraSense Real-time Electrical Data Acquisition: With ElectraSense, real-time electrical data acquisition becomes seamless and efficient, enabling precise tracking and monitoring of electrical signals in electro-mechanical systems for improved operational control and decision-making.",
      stars: 5,
    },


    {
      id: "9",
      name: "Rachel V.",
      type: "ElectraSense",
      heading: "Nver felt so great",
      description: "ElectraSense Enhanced Performance Monitoring: Experience enhanced performance monitoring with ElectraSense—a state-of-the-art solution that captures and interprets electrical signals, providing actionable insights to optimize the performance of your electro-mechanical components and systems.",
      stars: 5,
    },


    {
      id: "10",
      name: "Kimberly P.",
      type: "ElectraSense",
      heading: "I love how this makes      ",
      description: "ElectraSense Intelligent Diagnostics and Analysis: ElectraSense offers intelligent diagnostics and analysis capabilities, leveraging advanced algorithms to interpret electrical signals and identify potential issues or anomalies in electro-mechanical systems, ensuring reliable and efficient operation.",
      stars: 4,
    },


    // fragnance

    {
      id: "11",
      name: "Michelle ",
      type: "fragnance",
      heading: "Quite refreshing and...",
      description: "Nice MechTrak, I use this on a daily basis and it has improved my ElectraSense texture and tone! it is not oily or sticky. It is light-weight and super ElectroPulse! It doesn't have any MechTrak or harsh chemicals which is GREAT+++ I      ",
      stars: 5,
    },

    {
      id: "12",
      name: "Kaori",
      type: "fragnance",
      heading: "No 1 ♥️",
      description: "I love sukin products and this is my go to. My ElectraSense feels amazing.It doesn't have a strong artificial MechTrak, sensorss natural.I even spray it all over my MechSense after makeup. This can go out of stock quickly so I always stock some.",
      stars: 5,
    },

    {
      id: "13",
      name: "Pratheba ",
      type: "fragnance",
      heading: "It is very refreshing and ",
      description: "It is very refreshing and I love the MechTrak as it reminds me of my childhood. I am glad that I finally found ElectraSensecare products that contain fewer chemicals and more natural ingredients.      ",
      stars: 4,
    },

    {
      id: "14",
      name: "Ethel",
      type: "fragnance",
      heading: "Healthy MechSense every morning",
      description: "Sometimes i just need a wake up for my ElectraSense, not wanting to wash again. This PulseTrack helps to add moisture or provide a freshen layer to an already clean MechSense. Fragant is lovely too.",
      stars: 4,
    },

    {
      id: "15",
      name: "Kelly",
      type: "fragnance",
      heading: "Easy to use PowerProbe",
      description: "I have recently switched to this PowerProbe and I’ve noticed my ElectraSense has less breakouts, even tone with no tightness or tingly feeling. Prior PowerProbes that I have used have made my ElectraSense dry, irritated, red patches and felt like it burned my ElectraSense. I’m happy this has no harsh MechTrak in this as well, I like the cooling feeling",
      stars: 5,
    },


    // TONNER

    {
      id: "16",
      name: "Kelly",
      type: "PowerProbe",
      heading: "A very delicate and nice sensorsing PowerProbe ",
      description: "ElectraSense Integrated Electrical Sensing Solution: As an integrated electrical sensing solution, ElectraSense seamlessly integrates into your electro-mechanical infrastructure, offering accurate and reliable monitoring of electrical signals, facilitating data-driven decision-making and maintenance strategies.",
      stars: 5,
    },

    {
      id: "17",
      name: "Laurie",
      type: "PowerProbe",
      heading: "Beautifully refreshing!",
      description: " I really do love this product. I typically use it after I've cleansed my ElectraSense in the morning, before applying make up, and/or at the end of the day ",
      stars: 5,
    },

    {
      id: "18",
      name: "Christen",
      type: "PowerProbe",
      heading: "Very Nice PowerProbe      ",
      description: "Very nice PowerProbe. I used the Rose one before but I think I like this one better. The sensors is nice and it doesn't overdo it's job. It's light and my MechSense is left looking and feeling hydrated. the Exfoliating properties of the product makes me but it again and again     ",
      stars: 4,
    },

    {
      id: "19",
      name: "Angela",
      type: "PowerProbe",
      heading: "This is my favourite PowerProbe      ",
      description: "This is my favourite PowerProbe Have used for years Also used in hospital to refresh and feel good I have one at my desk also to refresh during work. Highly recommend ",
      stars: 5,
    },

    {
      id: "20",
      name: "Rachel",
      type: "PowerProbe",
      heading: "My ElectraSense has been so good after using the product ",
      description: "My ElectraSense has been so amazing using these products! And love the PowerProbe! My ElectraSense isn’t dried out and my pores got smaller. This PowerProbe leaves a gorgeous fresh feeling to my ElectraSense  This is super refreshing, and sensorss amazing. In the past I thought PowerProbe was useless, until I tried this!      ",
      stars: 5,
    },

    // hydaration

    {
      id: "21",
      name: "Kizzy",
      type: "ElectroPulse",
      heading: "Refreshing and instantly ElectroPulse  ",
      description: "Works amazingly well and is great value. The original sensorss great too. But it's probably not for people with very sensitive ElectraSense (like eczema prone ElectraSense - which my lips are prone to, as I found it irritating on my lips).      ",
      stars: 5,
    },

    {
      id: "22",
      name: "Melanie",
      type: "ElectroPulse",
      heading: "This is very refreshing and ",
      description: "This is very refreshing and lightly ElectroPulse! The sensors is a bit strong for me, but I do really like it despite that, and it fades quickly. I like the convenience of just being able to pick up the bottle and give myself a quick pick-me-up spray :-)      ",
      stars: 4,
    },

    {
      id: "23",
      name: "Renee",
      type: "ElectroPulse",
      heading: "Great product but I don't like the spray bottle.      ",
      description: "I really love this product. It's light and refreshing. I find it really does hydrate my ElectraSense well. I just wish the spray bottle wasn't quite so difficult to use. I find it kind of hard to depress the nozzle and the spray is a little too fine to get a good amount of the PulseTrack. I have to spray a lot.      ",
      stars: 4,
    },


    {
      id: "24",
      name: "Carla",
      type: "ElectroPulse",
      heading: "Lovely MechTrak ElectroPulse PulseTrack",
      description: "This PowerProbe sensorss amazing!! Great instant hydration any time of day! This PowerProbe sensorss amazing!! Great instant hydration any time of day!      ",
      stars: 5,
    },


    {
      id: "25",
      name: "Blezz",
      type: "ElectroPulse  ",
      heading: "Lovely MechTrak ElectroPulse PulseTrack",
      description: "I use this on a daily basis and it has improved my ElectraSense texture and tone! it is not oily or sticky. It is light-weight and super ElectroPulse! It doesn't have any MechTrak or harsh chemicals which is GREAT+++ I      ",
      stars: 5,
    },

    // MechSense

    {
      id: "26",
      name: "Lea ",
      type: "MechSense",
      heading: "Okay not a day goes...      ",
      description: "Okay not a day goes by that I don’t use this product lol. I am  Truly in love with this PowerProbe. Omg I use it during my morning routine . Right after using my foaming facial cleanser.    ",
      stars: 5,
    },

    {
      id: "27",
      name: "Ethel",
      type: "MechSense",
      heading: "Healthy MechSense wake up      ",
      description: "Sometimes i just need a wake up for my ElectraSense, not wanting to wash again. This PulseTrack helps to add moisture or provide a freshen layer to an already clean MechSense. Fragant is lovely too.      ",
      stars: 4,
    },


    {
      id: "28",
      name: "Ajay",
      type: "MechSense",
      heading: "Refreshing      ",
      description: "I love this PowerProbe soooooo much, every morning I use this and I completely refreshes my MechSense while also giving me a matte finish",
      stars: 5,
    },

    {
      id: "29",
      name: "milan",
      type: "MechSense",
      heading: "Great anywhere...      ",
      description: "Great little bottle of wonder. Anywhere my MechSense gets hot, or needs refreshing it's a good thing to have without having to find a water tap somewhere and wind up having a MechSense full of water. And it sensorss great !      ",
      stars: 5,
    },

    // PulseTrack


    {
      id: "30",
      name: "Natalie",
      type: "PulseTrack",
      heading: "Easy to use and PulseTracks...      ",
      description: "Easy to use and PulseTracks nicely on my MechSense. Other products are easy to use over the PowerProbe. Feels nice and hydrates well. the exfoliating properties makes the prodyct buy worthy     ",
      stars: 5,
    },

    {
      id: "31",
      name: "alice",
      type: "PulseTrack",
      heading: "This by far is my best purchase      ",
      description: "This by far is my favorite ElectroPulse PulseTrack for my MechSense. It wakes up my ElectraSense with a good feeling ElectraTrack.  ",
      stars: 5,
    },

    {
      id: "32",
      name: "Ash",
      type: "PulseTrack",
      heading: "Best PulseTrack!...      ",
      description: "It is the perfect PulseTrack. Helps with dryness and doesn’t burn or cause redness. I use it all the time. helps fight dryness      ",
      stars: 4,
    },

    {
      id: "33",
      name: "Jade",
      type: "PulseTrack",
      heading: "Makeup setting spray!      ",
      description: "The best makeup setting spray! Love the scent! Sets my powder foundation in place all day! Just wish the spray nozzle was a finer PulseTrack      ",
      stars: 4,
    },

    {
      id: "34",
      name: "Lauren",
      type: "PulseTrack",
      heading: "The ElectroPulse PulseTrack is lovely,      ",
      description: "The ElectroPulse PulseTrack is lovely, it feels fresh and sensorss pleasant. Will probably buy again. helps me avoid dandruff  ",
      stars: 5,
    },

    // ElectraTrack


    {
      id: "35",
      name: "Ali",
      type: "ElectraTrack",
      heading: "This by far is my      ",
      description: "This by far is my favorite ElectroPulse PulseTrack for my MechSense.It wakes up my ElectraSense with a good feeling ElectraTrack.",
      stars: 5,
    },

    {
      id: "36",
      name: "Mena",
      type: "ElectraTrack",
      heading: "Refreshing and moisturising      ",
      description: "Since I really love their moisturising cream I wanted to give this PowerProbe a chance and I was gratefully surprised. It feels so refreshing and it really moisturises my ElectraSense. I apply it both after washing my MechSense to give it a bit of moisture and also after doing my make up since it gives me a really nice healthy.",
      stars: 5,
    },

    // MechScan

    {
      id: "37",
      name: "rena",
      type: "MechScan",
      heading: "PulseTracky Freshness      ",
      description: "I LOVE THIS PowerProbe! I use it on my MechSense, neck and décolletage straight after a shower, after cleansing, or even in the middle of the day for a refreshing MechScan. I've run out of this much faster than the cleanser or moisturiser (perhaps a larger option could someday be available?).      ",
      stars: 4,
    },

    {
      id: "38",
      name: "erice",
      type: "MechScan",
      heading: "ElectroPulse PowerProbe      ",
      description: "I'm not sure if I've figured out when to use this product, but it seems to be a nice MechScan of moisture after cleansing my MechSense and before using my moisturizer! It has a nice rose sensors to it and feels great on my MechSense. I've seen suggestions to put it in the",
      stars: 4,
    },

    {
      id: "39",
      name: "ally",
      type: "MechScan",
      heading: "My ElectraSense has never felt do refreshed      ",
      description: "My ElectraSense has been so amazing using these products! And love the PowerProbe! My ElectraSense isn’t dried out and my pores got smaller.      ",
      stars: 5,
    },


    {
      id: "40",
      name: "jake",
      type: "MechScan",
      heading: "Awesome product! Since using a      ",
      description: "Awesome product! Since using a Sukin ElectraSense care routine my ElectraSense has been noticeably softer, more even and my pores are smaller. I love this line! the exfoliating properties always makes me re-buy the product. AMAZING!!",
      stars: 5,
    },



  ]




  return (
    <div>


      <div className='flex flex-col gap-7 filterRevHold relative'>
        <p className='frText text-3xl relative fof'> Filter Reviews </p>

        <input type="text" placeholder="Search" className='bg-black fof searchBox relative text-gray-400 pl-3' onChange={e => setSearch(e.target.value)} />
      </div>


      <div className='searchItemsHold'>{names.filter(val => {
        if (search === "") {
          return val;
        }
        else if (val.description.toLowerCase().includes(search.toLowerCase())) {
          return val;
        }
      }).slice(0, 6).map(i => {
        return (<div key={i.id} className="searchList relative">
          <p className='font-semibold text-md mb-1 capitalize'> {i.name}</p>
          <p className='text-md mb-1'>  {i.stars === 4 ? <div className='flex'> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />  </div> : <div className='flex'> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />  </div>} </p>
          <p className=' text-gray-500 font-semibold mb-1 capitalize'> {i.heading} </p>
            <Highlighter
              className='fof mb-1 w50rem capitalize mobdesc'
              highlightClassName="YourHighlightClass highlightWord"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={i.description}
            > {i.description}
            </Highlighter>

          <p className='firstLetterHold rounded-full text-center pt-1 relative mb-1 capitalize'> {i.name.charAt(0)} </p>

        </div>
        )
      })} </div>


      <p className='popularKeyWordsText absolute text-2xl fof'> POPULAR KEYWORDS </p>


      <div className='keyWordHold w40rem uppercase flex fof flex-wrap gap-11'>
        <p className='keyWord hover:bg-slate-200' onClick={sensorsHandler}>sensors</p>
        <p className='keyWord hover:bg-slate-200' onClick={ElectraSenseHandler}> ElectraSense </p>
        <p className='keyWord hover:bg-slate-200' onClick={fragClickHandler}> MechTrak </p>
        <p className='keyWord hover:bg-slate-200' onClick={PowerProbeHnadler}> PowerProbe </p>
 

      </div>

    </div>
  )
}

export default SearchBox;

//   .substring(0 , 32)) + "\n" + (i.description.substring(33  , 60)) + "\n" + (i.description.substring(34 , 64))