import React, { useState } from 'react';
import axios from 'axios';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const API_KEY = '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';

  const sendMessage = async () => {
    if (input.trim() === '') return;
    setMessages((prevMessages) => [...prevMessages, { text: input, fromUser: true }]);
    setInput('');
  
    try {
      const prompt = messages.map((message) => message.text).join('\n') + '\nUser: ' + input;
      const response = await axios.post(
        'https://api.openai.com/v1/engines/davinci-codex/completions',
        {
          prompt: prompt,
          max_tokens: 50,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
  
      const chatbotMessage = response.data.choices[0].text.trim();
      setMessages((prevMessages) => [...prevMessages, { text: chatbotMessage, fromUser: false }]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-end bg-gray-100 px-4 py-8">
      <div className="flex flex-col flex-grow">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-2 p-2 rounded-lg ${
              message.fromUser ? 'bg-blue-500 text-white self-end' : 'bg-gray-200 text-gray-800 self-start'
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="flex items-center mt-4">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          className="flex-grow border border-gray-300 rounded-l-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={sendMessage}
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-r-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatbot;






// import React, { useState } from 'react';
// import axios from 'axios';
// const openai = new OpenAI({
//     apiKey: process.env.REACT_APP_OPENAI_API_KEY,
//     dangerouslyAllowBrowser: true,
//   });
//   const handleSendMessage = (messageContent) => {
//     setMessages((prevMessages) => [
//       ...prevMessages,
//       { role: "user", content: messageContent },
//     ]);
//     chatData(messageContent);
//   };
//   const chatData = async (userMessage) => {
//     try {
//       const response = await fetch(
//         "https://api.openai.com/v1/chat/completions",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
//           },
//           body: JSON.stringify({
//             model: "gpt-3.5-turbo",
//             messages: [...messages, { role: "user", content: userMessage }],
//             temperature: 0.7,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Oops! Something went wrong while processing your request.");
//       }

//       const responseData = await response.json();

//       setMessages((prevMessages) => [
//         ...prevMessages,
//         {
//           role: "assistant",
//           content: responseData.choices[0].message.content,
//         },
//       ]);
//     } catch (error) {
//       console.error("Error while fetching chat data:", error);
//     }
//   };
//   return (
//     <div className="App">
//       {/* Your chatbot UI here */}
//     </div>
//   );
//   export default Chatbot;
  




