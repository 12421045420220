// import { motion } from 'framer-motion';
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// const Services = () => {
//   const services = [
//     {
//       id: 1,
//       title: 'Service 1',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service1.jpg',
//     },
//     {
//       id: 2,
//       title: 'Service 2',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service2.jpg',
//     },
//     {
//       id: 3,
//       title: 'Service 3',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet eros vel suscipit ultrices. In sed dui sed leo feugiat eleifend.',
//       image: '/service3.jpg',
//     },
//   ];
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   useEffect(() => {
//     axios
//     // https://api.synergyindustri.com/api/blog-posts-getall/
//     .get('https://api.synergyindustri.com/api/service-getall/')
//     .then((response) => {
//       console.log(response.data.description)
//       const a = response.data
//       console.log(a.image)
//       setBlogPosts(response.data);
//       setIsLoading(false);
//     })
//     .catch((error) => {
//       console.error('Error fetching blog posts:', error);
//       setIsLoading(false);
//     });
//     // fetchBlogPosts();
//   }, []);

// const handleChangePicture = (postId) => {
//     console.log(`Change picture clicked for blog post ${postId}`);
//     const handleImageChange = (event) => {
//       const imageFile = event.target.files[0];
//       if (imageFile) {
//         const reader = new FileReader();
//         reader.onload = (e) => {
//           const base64Image = e.target.result;
  
//           const title = prompt('Enter the new title:');
//           const description = prompt('Enter the new description:');
  
//           if (title && description) {
//             const token = localStorage.getItem('token');
//             const headers = {
//               Authorization: `token ${token}`,
//             };
  
//             axios
//               .put(`https://api.synergyindustri.com/api/services/${postId}/`, { image: base64Image, title, description }, { headers })
//               .then((response) => {
//                 console.log('Image changed successfully:', response.data);
//                 fetchBlogPosts(); // Refresh the blog post list
//               })
//               .catch((error) => {
//                 console.error('Error changing image:', error);
//               });
//           }
//         };
//         reader.readAsDataURL(imageFile);
//       }
//     };
  
//     const inputElement = document.createElement('input');
//     inputElement.type = 'file';
//     inputElement.accept = 'image/*';
//     inputElement.addEventListener('change', handleImageChange);
//     inputElement.click();
//   };

//   const handleAddImage = () => {
//     const handleImageChange = (event) => {
//       const imageFile = event.target.files[0];
//       if (imageFile) {
//         const title = prompt('Enter the title:');
//         const description = prompt('Enter the description:');
  
//         if (title && description) {
//           const reader = new FileReader();
//           reader.onload = (e) => {
//             const base64Image = e.target.result;
  
//             const formData = new FormData();
//             formData.append('image', base64Image);
//             formData.append('title', title);
//             formData.append('description', description);
  
//             const token = localStorage.getItem('token');
//             const headers = {
//               Authorization: `token ${token}`,
//               'Content-Type': 'multipart/form-data',
//             };
  
//             axios
//               .post('https://api.synergyindustri.com/api/services/create/', formData, { headers })
//               .then((response) => {
//                 console.log('Image added successfully:', response.data);
//                 fetchBlogPosts(); // Refresh the blog post list
//               })
//               .catch((error) => {
//                 console.error('Error adding image:', error);
//               });
//           };
//           reader.readAsDataURL(imageFile);
//         }
//       }
//     };
  
//     const inputElement = document.createElement('input');
//     inputElement.type = 'file';
//     inputElement.accept = 'image/*';
//     inputElement.addEventListener('change', handleImageChange);
//     inputElement.click();
//   };

//   const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Services</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      
//         {blogPosts.map((blogPosts) => (
//           <motion.div
//             key={blogPosts.id}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 0.2 }}
//             className="bg-gray-50 shadow-lg rounded-lg overflow-hidden hover:shadow-2xl hover:bg-gray-200 hover:cursor-pointer"
//           >
//             <img className="w-full h-48 object-cover" src={blogPosts.image} alt={blogPosts.title} />
//             <div className="p-4">
//               <h3 className="text-xl font-medium mb-2">{blogPosts.title}</h3>
//               <p className="text-gray-600">{blogPosts.description}</p>
//             </div>

//             {isLoggedIn && (

//             <div className='p-2 flex flex-row justify-end gap-2'>
            
//             <button className='text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1 '  onClick={() => handleChangePicture(blogPosts.id)}>
//             Edit
//             </button>
//             <button
//             className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
//             onClick={() => handleDelete(id)}
//           >
//             Delete
//           </button>
//             </div>
//             )}
//           </motion.div>
//         ))}
//         {isLoggedIn && (
//           <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//             <div className="">
//               <div className="h-48 flex items-center justify-center">
//                 <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
//                   Add Image
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
   
//     </div>
//   );
// };

// export default Services;





import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingLogo from './LoadingLogo';

const Services = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get('https://api.synergyindustri.com/api/service-getall/')
  //     .then((response) => {
  //       setBlogPosts(response.data);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching blog posts:', error);
  //       setIsLoading(false);
  //     });
  // }, []);


  // useEffect(() => {
  //   if (window.performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
  //     window.location.href = '/';
  //   }
  // }, []);


  const fetchTestimonials = () => {
    axios
      .get('https://api.synergyindustri.com/api/service-getall/')
      .then((response) => {
        setBlogPosts(response.data);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
        setIsLoading(false); // Set loading state to false even if there's an error
      });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);











  // const handleChangePicture = async (postId) => {
  //   const fileInput = document.createElement('input');
  //   fileInput.type = 'file';
  //   fileInput.accept = 'image/*';
  //   fileInput.click();

  //   fileInput.onchange = async (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const formData = new FormData();
  //       formData.append('image', file);

  //       try {
  //         const response = await axios.post(`https://api.synergyindustri.com/api/services/${postId}`, formData);

  //         if (response.status === 200) {
  //           const updatedPosts = blogPosts.map((post) => {
  //             if (post.id === postId) {
  //               return {
  //                 ...post,
  //                 image: response.data.image,
  //               };
  //             }
  //             return post;
  //           });

  //           setBlogPosts(updatedPosts);
  //         }
  //       } catch (error) {
  //         console.error('Error changing picture:', error);
  //       }
  //     }
  //   };
  // };









  const handleChangePicture = (postId) => {
    console.log(`Change picture clicked for blog post ${postId}`);
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result;
  
          const title = prompt('Enter the new title:');
          const description = prompt('Enter the new description:');
  
          if (title && description) {
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
            };
  
            axios
              .put(`https://api.synergyindustri.com/api/services/${postId}/`, { image: base64Image, title, description }, { headers })
              .then((response) => {
                console.log('Image changed successfully:', response.data);
                fetchTestimonials(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error changing image:', error);
              });
          }
        };
        reader.readAsDataURL(imageFile);
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };






































  // const handleAddImage = async () => {
  //   const fileInput = document.createElement('input');
  //   fileInput.type = 'file';
  //   fileInput.accept = 'image/*';
  //   fileInput.click();

  //   fileInput.onchange = async (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const formData = new FormData();
  //       formData.append('image', file);

  //       try {
  //         const response = await axios.post('https://api.synergyindustri.com/api/services/create/', formData);

  //         if (response.status === 200) {
  //           const newPost = {
  //             id: response.data.id,
  //             image: response.data.image,
  //             title: '',
  //             description: '',
  //           };

  //           setBlogPosts([...blogPosts, newPost]);
  //         }
  //       } catch (error) {
  //         console.error('Error adding image:', error);
  //       }
  //     }
  //   };
  // };



  const handleAddImage = () => {
    const handleImageChange = (event) => {
      const imageFile = event.target.files[0];
      if (imageFile) {
        const title = prompt('Enter the title:');
        const description = prompt('Enter the description:');
      
        if (title && description) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
  
            const formData = new FormData();
            formData.append('image', base64Image);
            formData.append('title', title);
            formData.append('description', description);
     
      
  
            const token = localStorage.getItem('token');
            const headers = {
              Authorization: `token ${token}`,
              'Content-Type': 'multipart/form-data',
            };
  
            axios
              .post('https://api.synergyindustri.com/api/services/create/', formData, { headers })
              .then((response) => {
                console.log('Image added successfully:', response.data);
                fetchTestimonials(); // Refresh the blog post list
              })
              .catch((error) => {
                console.error('Error adding image:', error);
              });
          };
          reader.readAsDataURL(imageFile);
        }
      }
    };
  
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', handleImageChange);
    inputElement.click();
  };



  const handleDelete = (postId) => {
    console.log(`Delete button clicked for blog post ${postId}`);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `token ${token}`,
    };

    axios
      .delete(`https://api.synergyindustri.com/api/services/${postId}/delete/`, { headers })
      .then((response) => {
        console.log('Blog post deleted successfully:', response.data);
        fetchTestimonials(); // Refresh the blog post list
      })
      .catch((error) => {
        console.error('Error deleting blog post:', error);
      });
  };















  // const handleDelete = async (postId) => {
  //   try {
  //     const response = await axios.delete(`https://api.synergyindustri.com/api/delete-blog-post/${postId}`);

  //     if (response.status === 200) {
  //       const updatedPosts = blogPosts.filter((post) => post.id !== postId);
  //       setBlogPosts(updatedPosts);
  //     }
  //   } catch (error) {
  //     console.error('Error deleting post:', error);
  //   }
  // };


  const [showFullDescription, setShowFullDescription] = useState(false); // New state for full description
  const toggleDescription = (postId) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold my-8 flex flex-row intem-center justify-center">Our Services</h1>
      
      {isLoading ? ( // Check if loading state is true
        <div className="flex items-center justify-center ">
          <LoadingLogo /> {/* Replace this with your loading spinner component */}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8">
          {blogPosts.map((blogPost) => (
            <motion.div
              key={blogPost.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-gray-50 shadow-lg rounded-lg overflow-hidden hover:shadow-2xl hover:bg-gray-200 hover:cursor-pointer"
            >
              <img className="w-full h-80 object-cover" src={blogPost.image} alt={blogPost.title} />
                  <div className='overflow-y-auto bg w-full' style={{ maxHeight: '60px' }}>
              <p className="text-xl font-semibold px-2" style={{ maxHeight: '60px' }}>{blogPost.title}</p>
            </div>
            <div className='overflow-y-auto bg w-full p-1' style={{ maxHeight: showFullDescription[blogPost.id] ? 'none' : '70px' }}>
            <h3 className="mt-2 text-gray-600 px-1" style={{ maxHeight: '90px' }}>{blogPost.description}</h3>
          </div>

                 <button
          onClick={() => toggleDescription(blogPost.id)}
          className="mt-2 bg-blue-500 text-sm px-2 py-1 rounded-lg ml-2 text-white hover:cursor-pointer hover:bg-blue-600 p-1"
        >
          {showFullDescription[blogPost.id] ? 'Show Less' : 'Show More'}
        </button>
        
              {isLoggedIn && (
                <div className="p-2 flex flex-row justify-end gap-2">
                  <button
                    className="text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1"
                    onClick={() => handleChangePicture(blogPost.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
                    onClick={() => handleDelete(blogPost.id)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </motion.div>
          ))}
          {isLoggedIn && (
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
              <div className="">
                <div className="h-48 flex items-center justify-center">
                  <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
                    Add Image
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Services;








// import { motion } from 'framer-motion';
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import LoadingLogo from './LoadingLogo';

// const Services = () => {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     setIsLoading(true); // Set loading state to true before making the API call

//     axios
//       .get('https://api.synergyindustri.com/api/blog-posts-getall/')
//       .then((response) => {
//         setBlogPosts(response.data);
//         setIsLoading(false); // Set loading state to false after receiving the response
//       })
//       .catch((error) => {
//         console.error('Error fetching blog posts:', error);
//         setIsLoading(false); // Set loading state to false in case of an error
//       });
//   }, []);

//   const handleChangePicture = async (postId) => {
//     // Rest of the code...
//   };

//   const handleAddImage = async () => {
//     // Rest of the code...
//   };

//   const handleDelete = async (postId) => {
//     // Rest of the code...
//   };

//   const isLoggedIn = !!(localStorage.getItem('token') && localStorage.getItem('user'));

//   return (
//     <div className="container mx-auto">
//       <h1 className="text-4xl font-bold my-8">Services</h1>
      
//       {isLoading ? ( // Check if loading state is true
//         <div className="flex items-center justify-center">
//           <LoadingLogo /> {/* Replace this with your loading spinner component */}
//         </div>
//       ) : (
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
//           {blogPosts.map((blogPost) => (
//             <motion.div
//               key={blogPost.id}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: 0.2 }}
//               className="bg-gray-50 shadow-lg rounded-lg overflow-hidden hover:shadow-2xl hover:bg-gray-200 hover:cursor-pointer"
//             >
//               <img className="w-full h-48 object-cover" src={blogPost.image} alt={blogPost.title} />
//               <div className="p-4">
//                 <h3 className="text-xl font-medium mb-2">{blogPost.title}</h3>
//                 <p className="text-gray-600">{blogPost.description}</p>
//               </div>
//               {isLoggedIn && (
//                 <div className="p-2 flex flex-row justify-end gap-2">
//                   <button
//                     className="text-white hover:bg-blue-800 mr-2 bg-blue-600 rounded-lg px-4 py-1"
//                     onClick={() => handleChangePicture(blogPost.id)}
//                   >
//                     Edit
//                   </button>
//                   <button
//                     className="text-white hover:bg-red-800 mr-2 bg-red-600 rounded-lg px-4 py-1"
//                     onClick={() => handleDelete(blogPost.id)}
//                   >
//                     Delete
//                   </button>
//                 </div>
//               )}
//             </motion.div>
//           ))}
//           {isLoggedIn && (
//             <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
//               <div className="">
//                 <div className="h-48 flex items-center justify-center">
//                   <button onClick={handleAddImage} className="bg-green-500 text-white px-4 py-2 rounded-lg">
//                     Add Image
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Services;