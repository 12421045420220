

import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa';

const Notification = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch notifications from the backend
    const fetchNotifications = async () => {
      try {
        const response = await fetch('http://127.0.0.1:8000/api/notifications/');
        console.log(response);
        const data = await response.json();
        console.log(data);
        setNotifications(data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);


  const handleBellClick = async () => {
    setIsPopupOpen(!isPopupOpen);
    if (isPopupOpen) {
      try {
        // Mark the notifications as viewed on the backend
        await fetch('http://127.0.0.1:8000/api/notifications/viewed/', {
          method: 'PUT',
        });
  
        // Clear the notifications on the frontend
        setNotifications([]);
      } catch (error) {
        console.error('Error marking notifications as viewed:', error);
      }
    }
  };

  return (
    <div className="fixed top-0 right-0 p-4 px-32">
      <div className="relative" onClick={handleBellClick}>
        <FaBell className="text-gray-600 text-3xl cursor-pointer absolute top-0 right-0" />
        {notifications.length > 0 && (
          <div className="notification-counter absolute top-0 right-0 flex items-center justify-center w-4 h-4 bg-red-500 text-white text-xs rounded-full">
            {notifications.length}
          </div>
        )}
      </div>
      {isPopupOpen && (
        <div className="notification-popup absolute top-0 mt-12 right-28 left bg-white border border-gray-200 rounded-lg shadow-lg hover:cursor-pointer max-w-screen-md max-h-screen overflow-y-auto">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div key={notification.id} className="border-b border-gray-200 p-4">
                <p>{notification.message}</p>
              </div>
            ))
          ) : (
            <div className="p-4">
              <p>No notifications</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notification;