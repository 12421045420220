
// // import React, { useState } from 'react';
// // import './Newsn.css'
// // import logo from "../assets/image15.jpg";
// // import logo1 from "../assets/image16.jpg";
// // import logo2 from "../assets/image17.jpg";
// // import Droga from "../assets/Droga.jpg";

// // const FormDownload = () => {
// //   const [name, setName] = useState('');
// //   const [email, setEmail] = useState('');
// //   const [department, setDepartment] = useState('');

// //   const handleFormDownload = () => {
// //     // Logic to generate and download the form
// //     const logoUrl = { Droga } // Replace with the actual path to your logo image
// //     const formContent = `Name: ${name}\nEmail: ${email}\nDepartment: ${department}`;

// //     const template = `
// //       <div style="text-align: center;">
// //         <img src="${Droga}" alt="Logo" style="max-width: 200px; margin-bottom: 20px;">
// //       </div>
// //       <h2 style="text-align: center; font-size: 24px;">Form Data:</h2>
// //       <pre style="font-size: 18px;">${formContent}</pre>
// //     `;

// //     const element = document.createElement('a');
// //     const file = new Blob([template], { type: 'text/html' });
// //     element.href = URL.createObjectURL(file);
// //     element.download = 'form.html';
// //     document.body.appendChild(element);
// //     element.click();
// //   };

// //   const handleFormSend = () => {
// //     // Logic to send the form data to the IT manager
// //     console.log('Sending form:', { name, email, department });
// //     // Additional logic can be added here, such as making an API call to send the form data
// //   };

// //   return (
// //     <div className="p-4">
// //       <h2 className="text-2xl font-bold mb-4">Fill in the Form</h2>
// //       <div className="mb-4">
// //         <label htmlFor="name" className="block mb-2">Name:</label>
// //         <input
// //           id="name"
// //           type="text"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={name}
// //           onChange={(e) => setName(e.target.value)}
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label htmlFor="email" className="block mb-2">Email:</label>
// //         <input
// //           id="email"
// //           type="email"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={email}
// //           onChange={(e) => setEmail(e.target.value)}
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label htmlFor="department" className="block mb-2">Department:</label>
// //         <input
// //           id="department"
// //           type="text"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={department}
// //           onChange={(e) => setDepartment(e.target.value)}
// //         />
// //       </div>
// //       <button
// //         className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4"
// //         onClick={handleFormDownload}
// //       >
// //         Download Form
// //       </button>
// //       <button
// //         className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
// //         onClick={handleFormSend}
// //       >
// //         Send Form
// //       </button>
// //     </div>
// //   );
// // };



// // const HomePage = () => {
// //   return (
// //     <div className="flex flex-col items-center justify-center p-8">
// //       <h1 className="text-4xl font-bold mb-4">Welcome to our Droga</h1>
// //       <div className="carousel">
// //         <img src={logo} alt="Welcome 1" className="carousel-image" />
// //         <img src={logo1} alt="Welcome 2" className="carousel-image" />
// //         <img src={logo2} alt="Welcome 3" className="carousel-image" />
// //       </div>
// //       <p className="text-lg">We are here to provide top-notch IT solutions to our organization.</p>
// //     </div>
// //   );
// // };

// // const Newsn = () => {
// //   return (
// //     <div>
// //       <HomePage />
// //       <FormDownload />
// //     </div>
// //   );
// // };

// // export default Newsn;




// import logo from "../assets/image15.jpg";
// import logo1 from "../assets/image16.jpg";
// import logo2 from "../assets/image17.jpg";
// import React, { useState, useEffect } from 'react';
// import Droga from "../assets/Droga.jpg";


// // const FormDownload = () => {
// //   const [name, setName] = useState('');
// //   const [email, setEmail] = useState('');
// //   const [department, setDepartment] = useState('');
// //   const [youid, setYouid] = useState('');
// //   const [description, setDescription] = useState('');

// //   const handleFormDownload = () => {
// //     // Logic to generate and download the form
// //     const logoUrl = '../assets/Droga.jpg'; // Replace with the actual path to your logo image
// //     const formContent = `Name: ${name}\nEmail: ${email}\nDepartment: ${department}` + `\nYou ID: ${youid}` + `\nDescription: ${description}`;

// //     const template = `
// //       <div style="text-align: center;">
// //         <img src="${logoUrl}" alt="Logo" style="max-width: 200px; margin-bottom: 20px;">
// //       </div>
// //       <h2 style="text-align: center; font-size: 24px;">Form Data:</h2>
// //       <pre style="font-size: 18px;">${formContent}</pre>
// //     `;

// //     const element = document.createElement('a');
// //     const file = new Blob([template], { type: 'text/html' });
// //     element.href = URL.createObjectURL(file);
// //     element.download = 'form.html';
// //     document.body.appendChild(element);
// //     element.click();
// //   };

// //   const handleFormSend = () => {
// //     // Logic to send the form data to the IT manager
// //     console.log('Sending form:', { name, email, department ,youid, description });
// //     // Additional logic can be added here, such as making an API call to send the form data
// //   };

// //   return (
// //     <div className="p-4 bg-dro_yellow w-2/3 justify-center items-center">
// //       <h2 className="text-2xl font-bold mb-4">Fill in the Form</h2>
// //       <div className="mb-4">
// //         <label htmlFor="name" className="block mb-2">Name:</label>
// //         <input
// //           id="name"
// //           type="text"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={name}
// //           onChange={(e) => setName(e.target.value)}
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label htmlFor="email" className="block mb-2">Email:</label>
// //         <input
// //           id="email"
// //           type="email"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={email}
// //           onChange={(e) => setEmail(e.target.value)}
// //         />
// //       </div>
// //       <div className="mb-4">
// //         <label htmlFor="department" className="block mb-2">Department:</label>
// //         <input
// //           id="department"
// //           type="text"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={department}
// //           onChange={(e) => setDepartment(e.target.value)}
// //         />
// //       </div>
// // <div className="mb-4">
// //         <label htmlFor="description" className="block mb-2">Description:</label>
// //         <input
// //           id="description"
// //           type="text"
// //           className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //           value={description}
// //           onChange={(e) => setDescription(e.target.value)}
// //         />
// // </div>

// //       <div className="mb-4">
// //       <label htmlFor="youid" className="block mb-2">You ID:</label>
// //       <input
// //         id="youid"
// //         type="text"
// //         className="border border-gray-300 rounded px-3 py-2 w-full text-lg"
// //         value={youid}
// //         onChange={(e) => setYouid(e.target.value)}
// //       />
// //     </div>


      
// //       <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4" onClick={handleFormDownload}>
// //         Download Form
// //       </button>
// //       <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={handleFormSend}>
// //         Send Form
// //       </button>
// //     </div>
// //   );
// // };




// const HomePage = () => {
//   const [activeCard, setActiveCard] = useState(0);
//   const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the popup

//   const cards = [
//     {
//       id: 1,
//       title: 'Welcome to Drogapharma',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
//       imageMobile: require("../assets/image15.jpg"),
//       imageDesktop: require("../assets/image15.jpg"),
//     },
//     {
//       id: 2,
//       title: 'Ready to get started',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
//       imageMobile: require("../assets/image16.jpg"),
//       imageDesktop: require("../assets/image16.jpg"),
//     },
//     {
//       id: 3,
//       title: 'Put your comment on the below form',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
//       imageMobile: require("../assets/image17.jpg"),
//       imageDesktop: require("../assets/image17.jpg"),
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
//     }, 8000);

//     return () => clearInterval(interval);
//   }, [cards.length]);

//   const navigateToFormatDocument = () => {
//     setShowPopup(true); // Show the popup when the button is clicked
//   };

//   const closePopup = () => {
//     setShowPopup(false); // Close the popup
//   };

//   return (
//     <div className="container mx-auto overflow-hidden z-0">
//       <div className="max-w-5xl mx-auto">
//         <picture>
//           <source media="(max-width: 767px)" srcSet={cards[activeCard].imageMobile} />
//           <source media="(min-width: 768px)" srcSet={cards[activeCard].imageDesktop} />
//           <img className="w-full h-80 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
//         </picture>
//         <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
//           <div className="=p-0">
//             <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
//             <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
//             <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-dro_yellow hover:text-black" onClick={navigateToFormatDocument}>
//               Go To requirement format
//             </button>
//           </div>
//         </div>
//       </div>
//       {showPopup && (
//         <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-10">
//           <div className="bg-white p-6 rounded-lg">
//             <h3 className="text-lg font-bold mb-2">Popup Title</h3>
//             <p>Popup content goes here...</p>
//             <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={closePopup}>
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const Newsn = () => {
//   return (
//     <div>
//       <HomePage />
//     </div>
//   );
// };

// export default Newsn;


import React, { useState, useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js';

const HomePage = () => {
  const [activeCard, setActiveCard] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const popupRef = useRef(null);

  const cards = [
    {
      id: 1,
      title: 'Welcome to Drogapharma',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
      imageMobile: require("../assets/image15.jpg"),
      imageDesktop: require("../assets/image15.jpg"),
    },
    {
      id: 2,
      title: 'Ready to get started',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
      imageMobile: require("../assets/image16.jpg"),
      imageDesktop: require("../assets/image16.jpg"),
    },
    {
      id: 3,
      title: 'Put your comment on the below form',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
      imageMobile: require("../assets/image17.jpg"),
      imageDesktop: require("../assets/image17.jpg"),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [cards.length]);

  const navigateToFormatDocument = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };
  const handleDownloadPDF = () => {
    const element = popupRef.current;

    const opt = {
      margin: 0,
      filename: 'popup.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  const handleSubmit = () => {
    console.log('Name:', name);
    console.log('ID:', id);
    console.log('Description:', description);
    console.log('Department:', department);
    console.log('Date:', date);
    console.log('Time:', time);

    setName('');
    setId('');
    setDescription('');
    setDepartment('');
    setDate('');
    setTime('');

    setShowPopup(false);
  };

  return (
    <div className="container mx-auto overflow-hidden z-0">
      <div className="max-w-5xl mx-auto">
        <picture>
          <source media="(max-width: 767px)" srcSet={cards[activeCard].imageMobile} />
          <source media="(min-width: 768px)" srcSet={cards[activeCard].imageDesktop} />
          <img className="w-full h-80 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
        </picture>
        <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
          <div className="=p-0">
            <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
            <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
            <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-dro_yellow hover:text-black" onClick={navigateToFormatDocument}>
              Go To requirement format
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-10">
          <div className="bg-white p-6 rounded-lg max-h-full overflow-auto mt-10 mb-10" ref={popupRef}>
          <div className="text-center mb-6">
          <img src="/path/to/logo.png" alt="Logo" className="mx-auto h-12" />
        </div>
            <h3 className="text-lg font-bold mb-2">Request Form</h3>
            <div className="mb-4">
              <label htmlFor="name" className="block font-bold mb-1">
                Name:
              </label>
              <input type="text" id="name" value={name} onChange={handleNameChange} className="border border-gray-300 rounded px-2 py-1" />
            </div>
            <div className="mb-4">
              <label htmlFor="id" className="block font-bold mb-1">
                ID:
              </label>
              <input type="text" id="id" value={id} onChange={handleIdChange} className="border border-gray-300 rounded px-2 py-1" />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block font-bold mb-1">
                Description:
              </label>
              <textarea id="description" value={description} onChange={handleDescriptionChange} className="border border-gray-300 rounded px-2 py-1" rows="3" />
            </div>
            <div className="mb-4">
              <label htmlFor="department" className="block font-bold mb-1">
                Department:
              </label>
              <input type="text" id="department" value={department} onChange={handleDepartmentChange} className="border border-gray-300 rounded px-2 py-1" />
            </div>
            <div className="mb-4">
              <label htmlFor="date" className="block font-bold mb-1">
                Date:
              </label>
              <input type="date" id="date" value={date} onChange={handleDateChange} className="border border-gray-300 rounded px-2 py-1" />
            </div>
            <div className="mb-4">
              <label htmlFor="time" className="block font-bold mb-1">
                Time:
              </label>
              <input type="time" id="time" value={time} onChange={handleTimeChange} className="border border-gray-300 rounded px-2 py-1" />
            </div>
            <div className="flex justify-end">
              <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2" onClick={closePopup}>
                Close
              </button>
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={handleSubmit}>
                Send
              </button>
              <button className="btn-primary" onClick={handleDownloadPDF}>Download PDF</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Newsn = () => {
  return (
    <div>
      <HomePage />
    </div>
  );
};

export default Newsn;