// import image98 from '../assets/98.png';
// import image1 from '../../assets/1.jpg';
export const store = [


    // best sellers 4



    {
        id: "1",
        name: "Prassure gauges",
        // price: 9,
        type: "bestSeller",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg"),
        hoverImg: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/98.png"),

    },

    {
        id: "2",
        name: "Pressure gauges",
        // price: 9,
        type: "bestSeller",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/57.jpg"),
        // primaryImage: require("C:/Users/pc/Desktop/j/react-e-commerce--main/src/assets/2.jpg"),
        hoverImg: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/58.jpg"),

    },

    {
        id: "3",
        name: "Mist Toner",
        // price: 9,
        type: "bestSeller",
        primaryImage:require ("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/60.jpg"),
        hoverImg: require ("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/test12.png"),

    },

    {
        id: "4",
        name: "Last product here",
        // price: 19,
        type: "bestSeller",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/hair.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/i5.jpg"),

    },

    // for navBar ka gift type

    {
        id: "6",
        name: "First Image",
        price: 12,
        type: "gift",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic4.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic3.png"),
    },

    {
        id: "7",
        name: "Second Image",
        price: 10,
        type: "gift",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic1.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic2.jpg"),

    },

    {
        id: "8",
        name: "Third Image",
        price: 15,
        type: "gift",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic5.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic7.png"),

    },

    // for NAv bar ka SHOP RANGE "body type"

    {
        id: "9",
        name: "COFFEE BODY SCRUB",
        price: 11,
        type: "navbar-BodyType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic8.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic9.png"),

    },

    {
        id: "10",
        name: "COCONUT BODY WASH",
        price: 18,
        type: "navbar-BodyType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic1.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/pic1.png"),

    },


    {
        id: "11",
        name: "COCONUT BODY WASH",
        price: 21,
        type: "navbar-BodyType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },




    // for NAv bar ka SHOP RANGE "travel type"

    {
        id: "12",
        name: "first to be replaced",
        price: 56,
        type: "navbar-TravelType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },



    {
        id: "13",
        name: "second to be replaced",
        price: 28,
        type: "navbar-TravelType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "14",
        name: "third to be replaced",
        price: 22,
        type: "navbar-TravelType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },


    {
        id: "15",
        name: "fourth to be replaced",
        price: 44,
        type: "navbar-TravelType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    // for navbar ka new type ke liye 

    {
        id: "16",
        name: "we Will replace this image",
        price: 52,
        type: "navbar-NewType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },


    {
        id: "17",
        name: " we Will replace this image",
        price: 47,
        type: "navbar-NewType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },


    {
        id: "18",
        name: "we Will replace this image",
        price: 21,
        type: "navbar-NewType",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627698.jpeg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    // actual products "BEST SELLER PRODUCTS" type "ourBestSellers"

    {
        id: "19",
        name: "ELECTRO-PNEUMATIC POSITIONER",
        price: 21,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },


    {
        id: "20",
        name: "VALVE POSITION MONITOR(LSB-1000)",
        price: 61,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (3).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "21",
        name: "VALVE POSITION MONITOR(LSB-3000)",
        price: 45,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (2).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "22",
        name: "AIR VOLUME BOOSTER",
        price: 84,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (4).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "23",
        name: "AIR FILTER REGULATOR(FR20)",
        price: 25,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (5).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "24",
        name: "ESV-S(SINGLE COIL)",
        price: 45,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (6).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),

    },

    {
        id: "25",
        name: "ESV-31&32",
        price: 157,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/download (2).png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/photo1695627802.jpeg"),
    },


    {
        id: "26",
        name: "I/P CONVERTER",
        price: 24,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/bestofbest.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/60.jpg"),

    },

    {
        id: "27",
        name: "SNAP ACTING RELAY",
        price: 75,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/parse7.jpg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/60.jpg"),

    },

    {
        id: "28",
        name: "POSITION TRANSMITTER",
        price: 78,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/parse9.jpg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/58.jpg"),

    },

    {
        id: "29",
        name: "SS2-CYLINDER",
        price: 57,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/parse10.jpg"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/57.jpg"),

    },

    {
        id: "30",
        name: "PPCL(3-15psi)",
        price: 88,
        type: "ourBestSellers",
        primaryImage: require("C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/bestofbest.png"),
        hoverImg: require( "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/55.jpg"),

    },

    // // for ig carosuel  type "IGPOSTS"

    {
        id: "31",

        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "32",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "33",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "34",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },


    {
        id: "35",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },


    {
        id: "36",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "37",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "38",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "39",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "40",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "41",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },

    {
        id: "42",
        type: "iGPosts",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",

    },


    // FOR UNDER 20 PAGE



    // body wash
    {
        id: "43",
        name: "NATURAL LEMON BODY WASH",
        price: 18,
        type: "bodywash all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 4,
        descr: "Our lightweight Leave-In Conditioner hydrates, gently detangles and tames frizz leaving hair smooth and manageable.            "

    },

    {
        id: "44",
        name: "NATURAL STRAWBERRY BODY WASH",
        price: 15,
        type: "bodywash all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "53",
        name: "NATURAL UBTAN BODY WASH",
        price: 15,
        type: "bodywash all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Hydrate and protect dry hands while caring for nails and cuticles through Soy Protein, Aloe Vera & Vitamin E"

    },





    {
        id: "54",
        name: "NATURAL NEEM BODY WASH",
        price: 11,
        type: "bodywash all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Seriously hydrate the delicate under eye area with our fragrance-free water gel.            "

    },


    // body loption
    {
        id: "46",
        name: "NATURAL BLUEBERRY BODY LOTION",
        price: 15,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Deliver an instant boost of moisture to skin with our ultra-hydrating gel cream."
    },

    {
        id: "47",
        name: "NATURAL KIWI BODY LOTION",
        price: 19,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Our glow-promoting cleanser gently exfoliates to remove dead skin cells, excess sebum & make-up."
    },

    {
        id: "48",
        name: "NATURAL AVACODA BODY LOTION",
        price: 14,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Our glow-promoting exfoliator is formulated with Kakadu plum, a natural source of Vitamin C, and Bromelain, a natural fruit...            "

    },


    {
        id: "55",
        name: "NATURAL LITCHI BODY LOTION",
        price: 16,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Kakadu Plum, a natural source of Vitamin C, Vitamin E and Australian bioactive complex rich in antioxidants brighten and...            "

    },

    {
        id: "56",
        name: "NATURAL TEATREE BODY LOTION",
        price: 18,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "57",
        name: "NATURAL TEATREE BODY LOTION",
        price: 18,
        type: "bodyLotion all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        stars: 5,
        descr: "Our soap-free Bergamot & Patchouli Body Wash delights and stimulates the senses while leaving skin clean and fresh.            "

    },

    //// bodyScrub

    {
        id: "49",
        name: "EXFOLIATING JELLY SCRUB",
        price: 19,
        type: "bodyScrub all",
        primaryImage: "C:/Users/zerih/Desktop/new1/react-e-commerce--main/src/assets/1.jpg",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_451cbd3f-1e8b-446a-a45e-61cc31826f69_large.jpg?v=1629210220",
        stars: 5,
        descr: "Our cleanser helps exfoliate dead skin cells & cleanses away impurities to promote a more even complexion.            "

    },

    {
        id: "50",
        name: "EXFOLIATING APPPLE SCRUB",
        price: 9,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Brightening_IlluminatingMoisturiser60ml_02_large.jpg?v=1629210218",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_cf1ff5a7-5a32-4bb0-a7ce-be69ccc9fcf0_large.jpg?v=1629210219",
        stars: 5,
        descr: " Our Color Luster Mask restores natural shine and radiance to color treated hair with Kakadu Plum & Birch Extracts.            "

    },

    {
        id: "51",
        name: "EXFOLIATING KIWI SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWash500mL1000x1000x144_large.jpg?v=1637072534",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWashpumpLifestyle1000x1000x144_large.jpg?v=1637072534",
        stars: 5,
        descr: "Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        "

    },

    {
        id: "58",
        name: "EXFOLIATING NEEM SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Treatment_Oil_50mL_Bottle_01_2_large.jpg?v=1599504672",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Hair_Oil_large.jpg?v=1599504672",
        stars: 5,
        descr: "Our natural, sheer touch tinted sunscreen for light to medium skin tones has been designed with daily use in...            "

    },

    {
        id: "59",
        name: "EXFOLIATING COCOA SCRUB",
        price: 16,
        type: "bodyScrub all",
        primaryImage: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Coffee_Coconut_Exfoliating_Masque_large.jpg?v=1599504663",
        hoverImg: "https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Exfoliating_Facial_Masque_100ml_OH_01_large.jpg?v=1599504663",
        stars: 5,
        descr: "Our 100% naturally derived, sheer touch facial sunscreen has been designed with daily use in mind, and heroes natural...            "

    },





]