// // import React, { useState, useEffect } from 'react';
// // import car1 from "../assets/car1.png";
// // import car2 from "../assets/car2.png";
// // import car3 from "../assets/car3.png";
// // import synergy1 from "../assets/synergy1.png";
// // import synergy2 from "../assets/synergy2.png";
// // import synergy3 from "../assets/synergy3.png";
// // const Home = () => {
// //   const [activeCard, setActiveCard] = useState(0);
// //   const cards = [
// //     {
// //       id: 1,
// //       title: 'Welcome to synergy',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
// //       imageMobile: require("../assets/synergy3.png"), // Image for mobile view
// //       imageDesktop: require("../assets/car122.png"), // Image for desktop view
// //     },
// //     {
// //       id: 2,
// //       title: 'Ready to get started',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
// //       imageMobile: require("../assets/synergy1.png"), // Image for mobile view
// //       imageDesktop: require("../assets/car222.png"), // Image for desktop view
// //     },
// //     {
// //       id: 3,
// //       title: 'Best products ever',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
// //       imageMobile: require("../assets/synergy2.png"), // Image for mobile view
// //       imageDesktop: require("../assets/car322.png"), // Image for desktop view
// //     },
// //   ];

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
// //     }, 4000);

// //     return () => clearInterval(interval);
// //   }, [cards.length]);

// //   return (
// //     <div className="container mx-auto">
// //       <div className="max-w-5xl mx-auto">
// //         <picture>
// //           {/* Mobile view */}
// //           <source media="(max-width: 767px)" srcSet={cards[activeCard].imageMobile} />
// //           {/* Desktop view */}
// //           <source media="(min-width: 768px)" srcSet={cards[activeCard].imageDesktop} />
// //           <img className="w-full h-96 object-cover pt-2 rounded-sm" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
// //         </picture>
// //         <div className="bg-gray-100 shadow-lg rounded-lg overflow-hidden my-8">
// //           <div className="p-6">
// //             <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
// //             <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
// //             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
// //               View Details
// //             </button>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Home;





// import React, { useState, useEffect } from 'react';
// import car1 from "../assets/car1.png";
// import car2 from "../assets/car2.png";
// import car3 from "../assets/car3.png";
// import synergy1 from "../assets/synergy1.png";
// import synergy2 from "../assets/synergy2.png";
// import synergy3 from "../assets/synergy3.png";

// const Home = () => {
//   const [activeCard, setActiveCard] = useState(0);
//   const [showFullDescription, setShowFullDescription] = useState(false); // State to toggle full description

//   const cards = [
//     {
//       id: 1,
//       title: 'Water and Wastewater Applications',
//       description: 'We are your trusted partner for supply, installation and commissioning of electromechanical equipment for water supply systems and wastewater treatment plants for both domestic and industrial applications. Please contact us for complete electromechanical solutions regardless of the size and complexity of your projects.',
//       imageMobile: require("../assets/car122.jpg"), // Image for mobile view
//       imageDesktop: require("../assets/car122.jpg"), // Image for desktop view
//     },
//     {
//       id: 2,
//       title: 'Industrial Automation Solutions',
//       description: 'Do you need a one-stop company for industrial automation solutions in Ethiopia? Then, you have come to the right place. Industrial automation is our specialization and the core business of our company. We provide an end to end solution including design, supply, installation, testing and commissioning of automation systems for various manufacturing plants, water supply facilities, waste water treatment plants and etc. We are adding great values for our customers in providing rehabilitation of existing automation systems and by providing need based maintenance supports. In addition, we provide custom-tailored automation trainings on Siemens and Rockwell Automation PLCs, RTUs, DCSs and SCADAs with our certified engineers.',
//       imageMobile: require("../assets/car122.jpeg"), // Image for mobile view
//       imageDesktop: require("../assets/car122.jpeg"), // Image for desktop view
//     },
//     {
//       id: 3,
//       title: 'Factory Erection Services',
//       description: 'Our experienced teams of engineers and technicians have got the right skills for erection and commissioning of factories of various applications. We provide top-notch mechanical, electrical and automation installation services with high level of professionalism and excellent workmanship. Please contact us if you have current need for such services or you would like to work with us in the future as your electromechanical erection partner.',
//       imageMobile: require("../assets/car222.jpeg"), // Image for mobile view
//       imageDesktop: require("../assets/car222.jpeg"), // Image for desktop view
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
//     }, 2000);

//     return () => clearInterval(interval);
//   }, [cards.length]);

//   const handleaboutUs = () => {
//     window.location = '/Aboutus';
//   };

//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };

//   return (
//     <div className="container mx-auto">
//       <div className="max-w-5xl mx-auto ">
//         <picture>
//           {/* Mobile view */}
//           <source media="(max-width: 400px)" srcSet={cards[activeCard].imageMobile} />
//           {/* Desktop view */}
//           <source media="(min-width: 400px)" srcSet={cards[activeCard].imageDesktop} />
//           <img className="w-full h-72 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
//         </picture>
//         <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
//           <div className="p-6">
//             <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
//             <p className="text-gray-600 mb-4 text-justify">
//               {showFullDescription ? (
//                 cards[activeCard].description
//               ) : (
//                 `${cards[activeCard].description.slice(0, 100)}...`
//               )}
//             </p>
//             {showFullDescription ? (
//               <button
//                 className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600"
//                 onClick={toggleDescription}
//               >
//                 View Less
//               </button>
//             ) : (
//               <button
//                 className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
//                 onClick={toggleDescription}
//               >
//                 Read More
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;





import React, { useState, useEffect } from 'react';
import car1 from "../assets/car1.png";
import car2 from "../assets/car2.png";
import car3 from "../assets/car3.png";
import synergy1 from "../assets/synergy1.png";
import synergy2 from "../assets/synergy2.png";
import synergy3 from "../assets/synergy3.png";

const Home = () => {
  const [activeCard, setActiveCard] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false); // State to toggle full description
  const [intervalTime, setIntervalTime] = useState(8000); // New state variable for interval time

  const cards = [
    {
      id: 1,
      title: 'Water and Wastewater Applications',
      description: 'We are your trusted partner for supply, installation and commissioning of electromechanical equipment for water supply systems and wastewater treatment plants for both domestic and industrial applications. Please contact us for complete electromechanical solutions regardless of the size and complexity of your projects.',
      imageMobile: require("../assets/formob1.png"), // Image for mobile view
      imageDesktop: require("../assets/31.png"), // Image for desktop view
    },
    {
      id: 2,
      title: 'Industrial Automation Solutions',
      description: 'Do you need a one-stop company for industrial automation solutions in Ethiopia? Then, you have come to the right place. Industrial automation is our specialization and the core business of our company. We provide an end to end solution including design, supply, installation, testing and commissioning of automation systems for various manufacturing plants, water supply facilities, waste water treatment plants and etc. We are adding great values for our customers in providing rehabilitation of existing automation systems and by providing need based maintenance supports. In addition, we provide custom-tailored automation trainings on Siemens and Rockwell Automation PLCs, RTUs, DCSs and SCADAs with our certified engineers.',
      imageMobile: require("../assets/formob2.png"), // Image for mobile view
      imageDesktop: require("../assets/32.png"), // Image for desktop view
    },

    {
      id: 3,
      title: 'Factory Erection Services',
      description: 'Our experienced teams of engineers and technicians have got the right skills for erection and commissioning of factories of various applications. We provide top-notch mechanical, electrical and automation installation services with high level of professionalism and excellent workmanship. Please contact us if you have current need for such services or you would like to work with us in the future as your electromechanical erection partner.',
      imageMobile: require("../assets/formob3.png"), // Image for mobile view
      imageDesktop: require("../assets/30.png"), // Image for desktop view
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
    }, intervalTime);

    return () => clearInterval(interval);
  }, [cards.length, intervalTime]); // Add intervalTime as a dependency

  const handleaboutUs = () => {
    window.location = '/Aboutus';
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
    setIntervalTime(showFullDescription ? 2000 : 50000); // Update interval time when description is toggled
  };

  return (
    <div className="container mx-auto">
      <div className="max-w-5xl mx-auto ">
        <picture>
          {/* Mobile view */}
          <source media="(max-width: 400px)" srcSet={cards[activeCard].imageMobile} />
          {/* Desktop view */}
          <source media="(min-width: 400px)" srcSet={cards[activeCard].imageDesktop} />
          <img className="w-full h-72 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard].imageDesktop} alt={cards[activeCard].title} />
        </picture>
        <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
            <p className="text-gray-600 mb-4 text-justify">
              {showFullDescription ? (
                cards[activeCard].description
              ) : (
                `${cards[activeCard].description.slice(0, 100)}...`
              )}
            </p>
            {showFullDescription ? (
              <button
                className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600"
                onClick={toggleDescription}
              >
                View Less
              </button>
            ) : (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={toggleDescription}
              >
                Read More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;





























































// when it become all dynamic this will work perfectly

// import React, { useState, useEffect } from 'react';

// const Home = () => {
//   const [activeCard, setActiveCard] = useState(0);
//   const [cards, setCards] = useState([]);

//   useEffect(() => {
//     // Fetch data from the endpoint
//     fetch('http://127.0.0.1:8000/api/whoweare-getall/')
//       .then((response) => response.json())
//       .then((data) => setCards(data))
//       .catch((error) => console.log(error));

//     const interval = setInterval(() => {
//       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleAboutUs = () => {
//     window.location = '/Aboutus';
//   };

//   return (
//     <div className="container mx-auto">
//       <div className="max-w-5xl mx-auto ">
//         <picture>
//           {/* Mobile view */}
//           <source media="(max-width: 400px)" srcSet={cards[activeCard]?.imageMobile} />
//           {/* Desktop view */}
//           <source media="(min-width: 400px)" srcSet={cards[activeCard]?.imageDesktop} />
//           <img className="w-full h-80 object-cover pt-2 shadow-lg rounded-2xl" src={cards[activeCard]?.imageDesktop} alt={cards[activeCard]?.title} />
//         </picture>
//         <div className="bg-gray-100 shadow-4xl rounded-2xl overflow-hidden my-8">
//           <div className="p-6">
//             <h2 className="text-2xl font-bold mb-2">{cards[activeCard]?.title}</h2>
//             <p className="text-gray-600 mb-4">{cards[activeCard]?.description}</p>
//             <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" onClick={handleAboutUs}>
//               View Details
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;


















































































































































































// // import React, { useState, useEffect } from 'react';
// // import car1 from "../assets/car1.png";
// // import car2 from "../assets/car2.png";
// // import car3 from "../assets/car3.png";
// // import synergy1 from "../assets/synergy1.png";
// // import synergy2 from "../assets/synergy2.png";
// // import synergy3 from "../assets/synergy3.png";

// // const Home = () => {
// //   const [activeCard, setActiveCard] = useState(0);
// //   const cards = [
// //     {
// //       id: 1,
// //       title: 'Welcome to synergy',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
// //       imageMobile: synergy3, // Image for mobile view
// //       imageDesktop: car1, // Image for desktop view
// //     },
// //     {
// //       id: 2,
// //       title: 'Ready to get started',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
// //       imageMobile: synergy1, // Image for mobile view
// //       imageDesktop: car2, // Image for desktop view
// //     },
// //     {
// //       id: 3,
// //       title: 'Best products ever',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
// //       imageMobile: synergy2, // Image for mobile view
// //       imageDesktop: car3, // Image for desktop view
// //     },
// //     {
// //       id: 4,
// //       title: 'New feature coming soon',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. exciting 4',
// //       imageMobile: synergy3, // Image for mobile view
// //       imageDesktop: car1, // Image for desktop view
// //     },
// //     {
// //       id: 5,
// //       title: 'Discover our latest offers',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. amazing 5',
// //       imageMobile: synergy1, // Image for mobile view
// //       imageDesktop: car2, // Image for desktop view
// //     },
// //   ];

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
// //     }, 4000);

// //     return () => clearInterval(interval);
// //   }, [cards.length]);

// //   return (
// //     <div className="container mx-auto">
// //       <div className="max-w-5xl mx-auto">
// //         <div
// //           className="w-full h-96 object-cover pt-2 shadow-lg rounded-2xl"
// //           style={{
// //             backgroundImage: `url(${cards[activeCard].imageDesktop})`,
// //             backgroundPosition: 'center',
// //             backgroundSize: 'cover',
// //           }}
// //         >
// //           <div className="bg-gray-100 bg-opacity-80 shadow-4xl rounded-2xl overflow-hidden my-8">
// //             <div className="p-6">
// //               <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
// //               <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
// //               <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
// //                 View Details
// //               </button>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="flex justify-center space-x-4">
// //           {cards.map((card) => (
// //             <div
// //               key={card.id}
// //               className={`w-16 h-16 bg-white rounded-full border-2 border-blue-500 cursor-pointer ${
// //                 activeCard === card.id - 1 ? 'border-opacity-100' : 'border-opacity-50'
// //               }`}
// //               onClick={() => setActiveCard(card.id - 1)}
// //             >
// //               <img
// //                 className="w-full h-full object-cover rounded-full"
// //                 src={card.imageMobile}
// //                 alt={card.title}
// //               />
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Home;






















// // import React, { useState, useEffect } from 'react';
// // import car1 from "../assets/car1.png";
// // import car2 from "../assets/car2.png";
// // import car3 from "../assets/car3.png";
// // import synergy1 from "../assets/synergy1.png";
// // import synergy2 from "../assets/synergy2.png";
// // import synergy3 from "../assets/synergy3.png";

// // const Home = () => {
// //   const [activeCard, setActiveCard] = useState(0);
// //   const cards = [
// //     {
// //       id: 1,
// //       title: 'Welcome to synergy',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
// //       imageMobile: synergy3, // Image for mobile view
// //       imageDesktop: car1, // Image for desktop view
// //     },
// //     {
// //       id: 2,
// //       title: 'Ready to get started',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
// //       imageMobile: synergy1, // Image for mobile view
// //       imageDesktop: car2, // Image for desktop view
// //     },
// //     {
// //       id: 3,
// //       title: 'Best products ever',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
// //       imageMobile: synergy2, // Image for mobile view
// //       imageDesktop: car3, // Image for desktop view
// //     },
// //     {
// //       id: 4,
// //       title: 'New feature coming soon',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. exciting 4',
// //       imageMobile: synergy3, // Image for mobile view
// //       imageDesktop: car1, // Image for desktop view
// //     },
// //     {
// //       id: 5,
// //       title: 'Discover our latest offers',
// //       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. amazing 5',
// //       imageMobile: synergy1, // Image for mobile view
// //       imageDesktop: car2, // Image for desktop view
// //     },
// //   ];

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
// //     }, 4000);

// //     return () => clearInterval(interval);
// //   }, [cards.length]);

// //   return (
// //     <div className="container mx-auto">
// //       <div className="max-w-5xl mx-auto">
// //         <div className="relative">
// //           <div
// //             className="w-full h-96 object-cover pt-2 shadow-lg rounded-2xl"
// //             style={{
// //               backgroundImage: `url(${cards[activeCard].imageDesktop})`,
// //               backgroundPosition: 'center',
// //               backgroundSize: 'cover',
// //             }}
// //           >
// //             <div className="bg-gray-100 bg-opacity-80 shadow-4xl rounded-2xl overflow-hidden my-8">
// //               <div className="p-6">
// //                 <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
// //                 <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
// //                 <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
// //                   View Details
// //                 </button>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="flex justify-center absolute bottom-0 left-0 right-0 mb-4">
// //             {cards.map((card) => (
// //               <div
// //                 key={card.id}
// //                 className={`w-16 h-16 bg-white rounded-full border-2 border-blue-500 cursor-pointer ${
// //                   activeCard === card.id - 1 ? 'border-opacity-100' : 'border-opacity-50'
// //                 }`}
// //                 onClick={() => setActiveCard(card.id - 1)}
// //               >
// //                 <img
// //                   className="w-full h-full object-cover rounded-full"
// //                   src={card.imageMobile}
// //                   alt={card.title}
// //                 />
// //               </div>
// //             ))}
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Home;


// import React, { useState, useEffect } from 'react';
// import car1 from "../assets/car1.png";
// import car2 from "../assets/car2.png";
// import car3 from "../assets/car3.png";
// import synergy1 from "../assets/synergy1.png";
// import synergy2 from "../assets/synergy2.png";
// import synergy3 from "../assets/synergy3.png";

// const Home = () => {
//   const [activeCard, setActiveCard] = useState(0);
//   const cards = [
//     {
//       id: 1,
//       title: 'Welcome to synergy',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. wow1',
//       imageMobile: synergy3, // Image for mobile view
//       imageDesktop: car1, // Image for desktop view
//     },
//     {
//       id: 2,
//       title: 'Ready to get started',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. nice 2',
//       imageMobile: synergy1, // Image for mobile view
//       imageDesktop: car2, // Image for desktop view
//     },
//     {
//       id: 3,
//       title: 'Best products ever',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. cool 3',
//       imageMobile: synergy2, // Image for mobile view
//       imageDesktop: car3, // Image for desktop view
//     },
//     {
//       id: 4,
//       title: 'New feature coming soon',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. exciting 4',
//       imageMobile: synergy3, // Image for mobile view
//       imageDesktop: car1, // Image for desktop view
//     },
//     {
//       id: 5,
//       title: 'Discover our latest offers',
//       description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. amazing 5',
//       imageMobile: synergy1, // Image for mobile view
//       imageDesktop: car2, // Image for desktop view
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveCard((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
//     }, 4000);

//     return () => clearInterval(interval);
//   }, [cards.length]);

//   return (
//     <div className="container mx-auto">
//       <div className="max-w-5xl mx-auto">
//         <div className="flex justify-center mb-4">
//           {cards.map((card) => (
//             <div
//               key={card.id}
//               className={`w-16 h-16 bg-white rounded-full border-2 border-blue-500 cursor-pointer ${
//                 activeCard === card.id - 1 ? 'border-opacity-100' : 'border-opacity-50'
//               }`}
//               onClick={() => setActiveCard(card.id - 1)}
//             >
//               <img
//                 className="w-full h-full object-cover rounded-full"
//                 src={card.imageMobile}
//                 alt={card.title}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="w-full h-96 object-cover pt-2 shadow-lg rounded-2xl">
//           <div
//             className="bg-gray-100 bg-opacity-80 shadow-4xl rounded-2xl overflow-hidden h-full"
//             style={{
//               backgroundImage: `url(${cards[activeCard].imageDesktop})`,
//               backgroundPosition: 'center',
//               backgroundSize: 'cover',
//             }}
//           >
//             <div className="p-6 h-full flex flex-col justify-end">
//               <h2 className="text-2xl font-bold mb-2">{cards[activeCard].title}</h2>
//               <p className="text-gray-600 mb-4">{cards[activeCard].description}</p>
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
//                 View Details
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;